<template>
  <div class="wet-section fill-height d-flex">
    <div class="container d-flex flex-column align-items-center justify-content-center">
      <div class="wet-success-icon my-4"></div>
      <p class="text-center" style="max-width:25rem;">
        {{ str.success.message_title }}
      </p>
      <div v-html="str.success.message_subtitle" class="mt-3" style="max-width:25rem;"/>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import common from '@/mixins/common';
import wpSection from '@/enums/wpSection';
import { success } from '@/enums/stringIndices';
import LogService from '@/services/LogService';

export default {
  mixins: [common],
  data() {
    return {
      str: {
        success,
      },
    };
  },
  computed: {
    ...mapState(['strings']),
  },
  async created() {
    Promise.all([
      this.getWordPressStrings([wpSection.SUCCESS], wpSection.SUCCESS),
      this.$emit('update:nextDisabled', false),
    ]);
    await this.$root.$on('buttonNext', this.next);
  },
  destroyed() {
    // unwatch next & back buttons click watcher
    this.$root.$off('buttonNext', this.next);
    // this.$root.$off('buttonBack', this.back);
  },
  methods: {
    next() {
      // this.$router.push({ name: 'home', params: { ...this.$route.params } });
      const partnerLink = window.sessionStorage.getItem('iFrameVacationSuccessUrl');
      LogService.log('partnerLink', partnerLink);
      window.location = partnerLink ?? `/home/${this.$route.params?.lang || ''}`;
    },
  },
};
</script>
<style lang="scss">
  .wet-success-icon {
    display: block;
    width: 5rem;
    height: 5rem;
    border-radius: 2.5rem;
    border: 2px solid var(--secondary);

    &::before {
      content: '';
      margin: 1.75rem 1rem;
      display: block;
      width: 2.5rem;
      height: 1.5rem;
      border-left: 4px solid var(--secondary);
      border-bottom: 4px solid var(--secondary);
      transform: translateY(-.5rem) rotateZ(-45deg);
    }
  }
</style>
