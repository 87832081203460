import Vue from 'vue';
import VueMeta from 'vue-meta';
// import anime from 'animejs/lib/anime.es.js'
import Hammer from 'hammerjs';
// import { v4 as uuid } from 'uuid';
import { nanoid } from 'nanoid';
/** lodash */
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import upperFirst from 'lodash/upperFirst';
import find from 'lodash/find';
import findKey from 'lodash/findKey';
import merge from 'lodash/merge';
import xor from 'lodash/xor';
import setWith from 'lodash/setWith';
import get from 'lodash/get';
import unionBy from 'lodash/unionBy';
import pickBy from 'lodash/pickBy';
import cloneDeep from 'lodash/cloneDeep';
import moment from '@/vendors/moment';
import Vuelidate from 'vuelidate';
import Cookies from 'js-cookie';
import {
  scroll,
  resize,
  clickOutside,
  orientation,
  collapse,
  smoothHeight,
} from '@/directives';
import { arrToSrcset } from '@/common/common';
import { axiosInstance } from '@/api';
import WebAppAPIService from '@/services/WebappAPIService';
import WPAPIService from '@/services/WPAPIService';
import App from './App.vue';
import router from './router';
import store from './store';

Vue.config.productionTip = false;

// prototype section
Vue.prototype.$lodash = {
  debounce,
  isEqual,
  upperFirst,
  find,
  findKey,
  merge,
  xor,
  setWith,
  get,
  unionBy,
  cloneDeep,
  pickBy,
};
// Vue.prototype.$anime = anime
Vue.prototype.$hammer = Hammer;
Vue.prototype.$uuid = nanoid;
Vue.prototype.$axios = axiosInstance;
Vue.prototype.$webAppAPI = new WebAppAPIService(store);
Vue.prototype.$wpAPI = new WPAPIService(axiosInstance, store);
Vue.prototype.$moment = moment;
Vue.prototype.$arrToSrcset = arrToSrcset;
Vue.prototype.$Cookies = Cookies;
Vue.prototype.$isDev = process.env.NODE_ENV === 'development'; /** true if app in dev mode */

// components
Vue.component('datepickerComponent', () => import(/* webpackChunkName: "Datepicker" */ '@/components/datepicker/DatePicker.vue'));
Vue.component('wetModal', () => import(/* webpackChunkName: "wet-modal" */ '@/components/wetModal.vue'));
Vue.component('accordeonComponent', () => import(/* webpackChunkName: "accordeon" */ '@/components/accordeonComponent.vue'));

// use section
Vue.use(VueMeta);
Vue.use(Vuelidate);

// directives
Vue.directive('scroll', scroll);
Vue.directive('resize', resize);
Vue.directive('click-outside', clickOutside);
Vue.directive('orientation', orientation);
Vue.directive('collapse', collapse);
Vue.directive('smooth-height', smoothHeight);

// iframe reizer
require('iframe-resizer/js/iframeResizer.contentWindow');

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
