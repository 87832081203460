<template>
  <div ref="intro" class="wet-intro">
    <span
      class="wet-intro__close"
      @click="onClose"
    />
    <canvas
      ref="canvas"
      class="wet-intro__canvas"
      :width="width"
      :height="height"
      @click="onNext"
    ></canvas>
    <div ref="frame" class="wet-intro__frame"></div>
    <div v-if="intro.items[current].title" class="wet-intro__text">
      {{ intro.items[current].title }}
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';

export default {
  data() {
    return {
      items: [],
      updateObserver: null,
      current: 0,
      width: 300,
      height: 150,
    };
  },
  computed: {
    ...mapState(['intro']),
    showed() {
      return !!this.intro;
    },
    currentElRect() {
      const el = this.items?.[this.current]?.el;
      let rect = {};
      if (el instanceof Element) {
        const elRect = el.getBoundingClientRect();
        rect = {
          ...elRect,
          top: elRect.top + window.scrollY,
          bottom: elRect.bottom + window.scrollY,
          left: elRect.left + window.scrollX,
          right: elRect.right + window.scrollX,
        };
      }
      return rect;
    },
  },
  watch: {
    items: {
      handler() {
        // if (items.length === 0) this.SET_INTRO(null);
      },
    },
    'intro.items': {
      handler() {
        this.getItems();
      },
    },
    currentElRect: {
      handler(rect) {
        this.drawCanvas(rect);
        this.setFrame(rect);
      },
    },
  },
  methods: {
    ...mapMutations(['SET_INTRO']),
    getItems() {
      const elems = this.intro?.items?.map((item) => {
        const newItem = { ...item };
        if (typeof item.el === 'string' && document) {
          newItem.el = document.querySelector(item.el);
        } else if (item.el instanceof Element) {
          newItem.el = item.el;
        }
        return newItem;
      });
      this.items = elems || [];
    },
    initUpdateDoc() {
      const target = document.body;
      const config = {
        childList: true,
        subtree: true,
      };
      const callback = this.ondocUpdate;
      this.updateObserver = new MutationObserver(callback);
      this.updateObserver.observe(target, config);
    },
    ondocUpdate() {
      this.getItems();
    },
    drawCanvas(rect) {
      if (!this.$refs.canvas) return;
      const ctx = this.$refs.canvas.getContext('2d');
      ctx.clearRect(0, 0, this.width, this.height);

      const elSize = {
        width: rect.right - rect.left + 16,
        height: rect.bottom - rect.top + 16,
        x: rect.left - 8,
        y: rect.top - 8,
      };

      ctx.globalCompositeOperation = 'source-out';

      ctx.fillStyle = 'rgba(0,0,0,1)';
      ctx.beginPath();
      ctx.moveTo(elSize.x + elSize.width, elSize.y + elSize.height);
      ctx.arcTo(elSize.x, elSize.y + elSize.height, elSize.x, elSize.y, 16);
      ctx.arcTo(elSize.x, elSize.y, elSize.x + elSize.width, elSize.y, 16);
      ctx.arcTo(elSize.x + elSize.width, elSize.y,
        elSize.x + elSize.width, elSize.y + elSize.height, 16);
      ctx.arcTo(elSize.x + elSize.width, elSize.y + elSize.height,
        elSize.x, elSize.y + elSize.height, 16);
      ctx.fill();

      ctx.fillStyle = 'rgba(0,0,0,0.6)';
      ctx.fillRect(0, 0, this.width, this.height);
    },
    setCanvasSize() {
      this.width = document.documentElement.clientWidth;
      this.height = document.body.clientHeight;
    },
    resize() {
      this.setCanvasSize();
      this.drawCanvas(this.currentElRect);
      this.setFrame(this.currentElRect);
    },
    onClose() {
      this.SET_INTRO(null);
    },
    onNext() {
      if (this.items.length - 1 < this.current) {
        this.current += 1;
      } else {
        this.onClose();
      }
    },
    setFrame(rect) {
      const { intro } = this.$refs;
      if (intro) {
        intro.style.setProperty('--wet-intro-y', `calc(${rect.top}px - .5rem)`);
        intro.style.setProperty('--wet-intro-x', `calc(${rect.left}px - .5rem)`);
        intro.style.setProperty('--wet-intro-w', `calc(${rect.right - rect.left}px + 1rem)`);
        intro.style.setProperty('--wet-intro-h', `calc(${rect.bottom - rect.top}px + 1rem)`);
      }
    },
  },
  mounted() {
    this.initUpdateDoc();

    this.$root.$on('resize', this.resize);

    this.resize();
  },
  updated() {
    this.getItems();
  },
  destroyed() {
    this.SET_INTRO(null);

    if (this.updateObserver instanceof MutationObserver) this.updateObserver.disconnect();

    this.$root.$off('resize', this.resize);
  },
};
</script>

<style lang="scss">
.wet-intro {
  position: absolute;
  top: 0;
  height: 100%;
  left: 0;
  width: 100%;
  z-index: 9999;

  &__frame {
    position: absolute;
    top: var(--wet-intro-y);
    left: var(--wet-intro-x);
    width: var(--wet-intro-w);
    height: var(--wet-intro-h);
    border-radius: 16px;
    border: 2px solid var(--primary);
    pointer-events: none;
    box-shadow: 0px 0px 0px 20px rgba(255, 255, 255, 0.5),
      0px 0px 0px 10px rgba(255, 255, 255, 0.5);
    animation-duration: 1s;
    animation-name: shadowwave;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

    @keyframes shadowwave {
      from {
        box-shadow: 0px 0px 10px 20px rgba(255, 255, 255, 0.5),
          0px 0px 1px 10px rgba(0, 0, 0, 0.5),
          0px 0px 1px 0px rgba(255, 255, 255, 0.5),
          0px 0px 1px 0px rgba(255, 255, 255, 0.5);
      }

      50% {
        box-shadow: 0px 0px 10px 20px rgba(255, 255, 255, 0.5),
          0px 0px 1px 20px rgba(0, 0, 0, 0.25),
          0px 0px 1px 10px rgba(255, 255, 255, 1),
          0px 0px 1px 0px rgba(255, 255, 255, 0.5);
      }

      to {
        box-shadow: 0px 0px 10px 20px rgba(255, 255, 255, 0.5),
          0px 0px 1px 20px rgba(0, 0, 0, 0.0),
          0px 0px 1px 20px rgba(255, 255, 255, 0),
          0px 0px 1px 10px rgba(0, 0, 0, 0.5);
      }
    }
  }

  &__text {
    position: absolute;
    top: calc(var(--wet-intro-y) - 3rem);
    left: var(--wet-intro-x);
    font-weight: bold;
    color: var(--primary);
    background-color: rgba(255, 255, 255, .9);
    border-radius: 1rem;
    font-size: 1rem;
    transform: translateY(-100%);
    pointer-events: none;
    padding: 1rem;
    margin: 1rem;

    @media (min-width: 768px) {
      font-size: 1.25rem;
    }
  }

  &__close {
    position: absolute;
    right: 2rem;
    top: 2rem;
    width: 3rem;
    height: 3rem;
    background-color: rgba(white, .3);
    border-radius: var(--border-radius);
    cursor: pointer;

    &::before, &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 2rem;
      height: 2rem;
      border-left: 2px solid white;
      transform-origin: 50% 0;
    }

    &::before {
      transform: translate(1.85rem, 1.5rem) rotate(45deg);
    }

    &::after {
      transform: translate(.45rem, .2rem) rotate(-45deg);
    }
  }
}
</style>
