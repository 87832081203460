var render = function render(){var _vm=this,_c=_vm._self._c;return _c('aside',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.resize),expression:"resize"}]},[_c('div',{staticClass:"wet-drawer-overlay",class:{
      'active': _vm.drawer,
      'mobile': _vm.mobile,
    },on:{"click":function($event){return _vm.$emit('close')}}}),_c('div',{ref:"drawer",staticClass:"wet-drawer",class:{
      'active': _vm.drawer,
      'mobile': _vm.mobile,
    }},[_c('div',{staticClass:"wet-drawer-inner"},[_c('div',{staticClass:"wet-drawer-content"},[_vm._t("default")],2),_c('div',{staticClass:"p-3 d-md-none"},[_vm._v(" © Wetterheld 2020 ")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }