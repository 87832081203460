const scroll = {
  inserted: (el, binding) => {
    window.addEventListener('scroll', binding.value);
  },
  unbind: (el, binding) => {
    window.removeEventListener('scroll', binding.value);
  },
};

const resize = {
  inserted: (el, binding) => {
    window.addEventListener('resize', binding.value);
  },
  unbind: (el, binding) => {
    window.removeEventListener('resize', binding.value);
  },
};

const orientation = {
  inserted: (el, binding) => {
    window.addEventListener('orientationchange', binding.value);
  },
  unbind: (el, binding) => {
    window.removeEventListener('orientationchange', binding.value);
  },
};

const collapse = {
  inserted: (el, binding) => {
    const value = typeof binding.value === 'object' ? binding.value.value : binding.value;
    const minHeight = typeof binding.value === 'object' ? binding.value.minHeight : '0px';
    const wrapper = el;
    wrapper.style.transition = 'max-height 0s ease-out';
    wrapper.style.overflow = 'hidden';
    wrapper.style.maxHeight = value ? 'null' : minHeight;
    wrapper.style.transition = 'max-height 0.2s ease-out';
  },
  update: (el, binding) => {
    const value = typeof binding.value === 'object' ? binding.value.value : binding.value;
    const minHeight = typeof binding.value === 'object' ? binding.value.minHeight : '0px';
    const wrapper = el;
    if (!value) {
      wrapper.style.maxHeight = minHeight;
    } else {
      wrapper.style.maxHeight = `${wrapper.scrollHeight}px`;
    }
  },
  // unbind: (el, binding) => {
  //   window.removeEventListener('resize', binding.value);
  // },
};

const smoothHeight = {
  componentUpdated: (el) => {
    const wrapper = el;
    let h = 0;
    const childs = el.childNodes;
    childs.forEach((child) => {
      h += child.clientHeight;
    });
    wrapper.style.transition = 'all 0.2s ease-out';
    wrapper.style.overflow = 'hidden';
    wrapper.style.height = `${h}px`;
  },
};

const clickOutside = {
  // call the binded callback if click not on yourself element
  inserted: (el, binding) => {
    const element = el;
    element.$clickOutsideEvent = (e) => {
      if (!element.contains(e.target) && element !== e.target) {
        binding.value(e);
      }
    };
    window.addEventListener('click', element.$clickOutsideEvent);
  },
  unbind: (el) => {
    window.removeEventListener('click', el.$clickOutsideEvent);
  },
};

export {
  scroll,
  resize,
  clickOutside,
  orientation,
  collapse,
  smoothHeight,
};
