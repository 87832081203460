<template>
  <header
    v-if="!hide"
    class="wet-header"
    :class="{'has-wet-progress': progress, 'has-wet-header-message': $slots.message}"
    @transitionend="setStick"
  >
    <div :class="containered ? 'container-lg' : 'container-fluid'">
      <div class="row header-move">
        <div
          class="col text-center is-size-6 font-weight-bold empty-ph"
          v-if="title || skeleton && skeleton.includes('title')"
        >{{title}}</div>
        <div class="centered" v-if="menu" :class="smallScreen ? 'col-auto' : 'col-4'">
          <a
            role="button"
            class="wet-menu-toggle"
            @click.prevent="$emit('update:drawer', !drawer)"
          >
            <span></span>
            <span></span>
            <span></span>
          </a>
          <nav class="wet-nav wet-header-nav">
            <ul id="menu-front-page-menu" class="reset flex wet-nav-list">
              <li
                id="menu-item-801"
                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home"
                :class="{
                  'menu-item-has-children': item.submenu,
                }"
                v-for="(item, id) in menu"
                :key="id"
              >
                <a :href="item.href">{{item.title}}</a>
                <ul
                  v-if="item.submenu"
                  class="sub-menu"
                >
                  <li
                    v-for="(subitem, subid) in item.submenu"
                    :key="subid"
                  >
                    <router-link :to="subitem.href" >{{subitem.title}}</router-link>
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
        </div>
        <a
          :href="homeLink"
          class="col-auto position-absolute wet-header-icon"
          v-if="homeIcon"
          v-html="homeIcon"
        ></a>
        <div class="centered"
             :class="smallScreen ? 'col' : 'col-4'"
             v-if="logo">
          <div class="row no-gutters">
            <div class="col text-center">
              <a :href="logo.href">
                <picture>
                  <img
                    :src="logo.image"
                    alt="logo"
                    class="wet-logo"
                    @load="$event.target.classList.toggle('empty-img', false)"
                  >
                </picture>
              </a>
            </div>
            <template v-if="showPartner && partnerLogo.url">
              <div class="col-auto">
                <wet-icon class="wet-icon mx-2 text-secondary" type="plus" />
              </div>
              <div class="col">
                <a href="#" class="">
                  <wp-picture :image="partnerLogo" />
                </a>
              </div>
            </template>
          </div>
        </div>
        <div class="align-self-end centered text-right info-block"
             :class="smallScreen ? 'col-auto' : 'col-4'"
             v-if="phoneBlock || langs">
          <div class="flex-row align-items-center justify-content-end">
            <div class="phone-block" v-if="phoneBlock">
              <div style="text-align: right;">
                <img
                  alt=""
                  :src="phoneBlock.icon"
                  @load="$event.target.classList.toggle('empty-img', false)"
                  class="phone-icon empty-img"
                >
                <a
                  :href="`tel:${phoneBlock.phone}`"
                  class="phone-link empty-ph"
                >{{phoneBlock.phone}}</a>
                <div class="working-time empty-ph">{{phoneBlock.time}}</div>
              </div>
            </div>
            <ul class="wpm-language-switcher switcher-dropdown" v-if="langs">
              <li :class="`item-language-main item-language-${currentLang.lang}`">
                <span>
                  <img
                    :alt="currentLang.title"
                    width="18"
                    height="12"
                    :data-src="currentLang.icon"
                    class="lazyload"
                    :src="currentLang.icon"
                  >
                  <span>{{currentLang.title}}</span>
                </span>
                <ul class="language-dropdown shadow">
                  <li
                    :class="`item-language-${item.lang}`"
                    v-for="(item, id) in langsFiltered"
                    :key="id"
                  >
                    <router-link
                      :to="{params: {lang: item.lang === 'en' ? null : item.lang}}"
                      :data-lang="item.lang"
                    >
                      <img
                        width="18"
                        height="12"
                        :alt="item.title"
                        :data-src="item.icon"
                        class="lazyload"
                        :src="item.icon"
                      >
                      <span>{{item.title}}</span>
                    </router-link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="wet-progress" v-if="progress">
        <div
          class="wet-progress-step"
          :class="active > index ? 'active' : ''"
          v-for="(step, index) in Array(progress[1])"
          :key="index"
        ></div>
      </div>
      <slot name="message"></slot>
    </div>
  </header>
</template>
<script>
import { mapState } from 'vuex';
import wpPicture from '@/components/ui/wpPictureUi.vue';
import partnerCodeMixin from '@/mixins/partner-code';
import configEnv from '@/enums/configEnv';
import LogService from '@/services/LogService';

export default {
  mixins: [partnerCodeMixin],
  props: {
    drawer: Boolean,
    langs: Array,
    menu: Array,
    logo: Object,
    phoneBlock: Object,
    title: String,
    homeIcon: String,
    homeLink: {
      type: String,
      default: `${window.location.origin}/home/de/${configEnv.logo_redirect_page}`,
    },
    containered: Boolean,
    skeleton: Array,
    progress: Array,
    hide: Boolean, /** if true - header will no render */
    showPartner: Boolean, /** show logo of partner */
  },
  components: {
    wetIcon: () => import('@/components/wetIcon.vue'),
    wpPicture,
  },
  data() {
    return {
      active: 0,
      checkWindowWidth: window.innerWidth >= 768,
      smallScreen: window.innerWidth <= 576,
    };
  },
  computed: {
    ...mapState(['strings']),
    currentLang() {
      const current = this.$route.params.lang ? this.$route.params.lang.toLowerCase() : 'en';
      LogService.log('currentLang', current, this.langs);
      return this.langs.filter((el) => el.lang === current)[0];
      // return this.$_.filter(this.langs, { 'lang': current})[0]
    },
    langsFiltered() {
      const current = this.$route.params.lang ? this.$route.params.lang.toLowerCase() : 'en';
      return this.langs.filter((el) => el.lang !== current);
      // return this.$_.reject(this.langs, { 'lang': current})
    },
    partnerLogo() {
      let logo = {};
      // get partner code
      const { partnerCode } = this;

      // get logo
      if (partnerCode && partnerCode !== 'wetterheld') {
        const partner = this.strings
          .commonApp?.partners?.filter((p) => p.partner_code === partnerCode)[0];
        if (partner) logo = partner.logo;
      }
      return logo;
    },
  },
  watch: {
    progress: {
      immediate: true,
      handler(p) {
        if (p?.[0]) [this.active] = p;
      },
    },
  },
  mounted() {
    // set stick padding
    this.setStick();
    window.addEventListener('resize', this.onResize);
    this.$root.$on('resize', this.resize);
    this.$root.$on('scroll', this.scroll);
  },
  destroyed() {
    // unwatch
    window.removeEventListener('resize', this.onResize);
    this.$root.$off('resize', this.resize);
    this.$root.$off('scroll', this.scroll);

    // document.documentElement.style.setProperty('--header-height', '0px');
  },
  updated() {
    this.setStick();
  },
  methods: {
    onResize() {
      LogService.log('windowWidth', window.innerWidth);
      this.checkWindowWidth = window.innerWidth >= 768;
      this.smallScreen = window.innerWidth <= 576;
    },
    setStick() {
      // set the header sticky & change header-height css var
      if (document) {
        const header = document.querySelector('.wet-header');
        const root = document.documentElement;
        let h = '0px';
        if (header) {
          // set header-height css var
          h = this.hide ? '0px' : `${header.offsetHeight}px`;
          root.style.setProperty('--header-height', h);
          const next = header.nextSibling;
          if ('getBoundingClientRect' in next) {
            const offset = next.getBoundingClientRect().top;
            if (Math.abs(offset) > 0) {
              header.classList.toggle('stick', true);
            } else header.classList.toggle('stick', false);
          }
        }
        root.style.setProperty('--header-height', h);
      }
    },
    resize() {
      // call on event window.resize
      this.setStick();
    },
    scroll() {
      // call on event window.scroll
      this.setStick();
    },
  },
};
</script>
<style lang="scss">
  .wet-header {
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 9;
    border-bottom: 1px solid transparent;
  }
  .wet-header.has-wet-header-message {
    box-shadow: 0 1rem 1rem rgba(0,0,0,.1);
  }
  .wet-header.stick {
    background-color: rgba(255, 255, 255, 0.9);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    border-color: #EFF2F5;
  }
  .wet-header .container, .container-lg, .wet-header .container-fluid {
    // max-width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 20px;
    padding-left: 20px;
  }
  @media only screen and (max-width: 1200px) {
    .wet-header .container, .wet-header .container-fluid {
      padding: 10px;
    }
  }
  @media only screen and (max-width: 768px) {
    .wet-header .wet-nav {
      display: none;
    }
  }
  @media only screen and (max-width: 768px) {
    .wet-header .wet-button.wet-button-small {
      padding: 3px 0 0;
      background-color: transparent;
      -webkit-box-shadow: none;
      box-shadow: none;
      font-weight: 600;
      white-space: nowrap;
    }
    .wet-button-dropdown-current{
      color: var(--primary);
    }
  }
  .wet-nav-list li {
    font-weight: 600;
    font-size: 16px;
    color: #000000;
  }
  .wet-nav-list li:not(:last-child) {
    margin-right: 30px;
  }
  .wet-nav-list li a:hover {
    color: var(--primary);
  }
  .wet-header-icon svg {
    width: 1.25rem;
    height: 1.25rem;
    fill: var(--septenary);
  }
  header .phone-block {
    display: block;
    z-index: 99;
    font-size: 16px;
    font-weight: 600;
    text-align: right;
    margin-right: 15px;
    position: relative;
  }

  header .phone-block .phone-icon {
    width: 22px;
    height: 22px;
  }

  header .phone-block .phone-link {
      min-width: 5rem;
  }

  header .phone-block .working-time {
      box-sizing: border-box;
      margin-left: 10px;
      font-size: 15px;
      line-height: 1;
      color: #555;
      font-weight: 200;
      min-width: 5rem;
  }

  .centered{
    margin-bottom: auto;
    margin-top: auto;
  }

  @media only screen and (max-width: 1200px) {
    header .phone-block {
        margin-right: 5px;
    }
    header .info-block{
      padding-left: 0;
      padding-right: 0;
    }
  }
  @media only screen and (max-width: 1000px) {
    header .phone-block {
        margin: 0 5px 10px 0;
        width: 100%;
    }
  }
  @media only screen and (max-width: 767px) {
    header .phone-block .phone-icon {
      width: 16px;
      height: 16px;
    }

    header .phone-block {
      font-size: 12px;
    }
    header .phone-block {
        margin: 0 0px 10px 0;
        width: 100%;
    }
    header .phone-block .working-time{
      margin-left: 0;
      font-size: 10px;
    }
    .wet-logo{
      float: left;
    }
  }

  @media only screen and (max-width: 576px) {
    header .phone-block .phone-icon {
      width: 12px;
      height: 12px;
    }

    header .phone-block {
      font-size: 10px;
    }
    header .phone-block {
      margin: 0 0px 10px 0;
      width: 100%;
    }
    header .phone-block .working-time {
      margin-left: 0;
      font-size: 8px;
    }
  }

  /* Lang */
  header .wpm-language-switcher{
    display: block;
    z-index: 99;
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    margin-left: 15px;
    position: relative;
  }
  header .wpm-language-switcher:before{
      content: "";
      width: 20px;
      height: 20px;
      background-image: url("~@/assets/images/svg/arrow-down.svg");
      background-position: center center;
      background-size: 10px;
      background-repeat: no-repeat;
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translate(0, -50%);
      transition: all ease 0.2s;
  }
  header .wpm-language-switcher:hover:before{
    transition: all ease 0.2s;
    transform: translate(0, -50%) rotate(-180deg);
  }
  .wet-header.has-wet-progress {
    border-bottom: none;
  }
  .wet-progress {
    display: flex;
    margin-top: 15px;
    margin-bottom: -16px;
  }
  .wet-progress-step {
    flex: 1;
    height: 3px;
    background: linear-gradient(90deg, var(--primary) 0%, var(--primary) 49%, #cccccc 50%);
    background-size: 210% 100%;
    background-position-x: 100%;
    transition: 1s all;
  }
  @media only screen and (max-width: 1200px) {
    .wet-progress {
      margin-top: 10px;
      margin-bottom: -11px;
    }
    .wet-progress-step {
      height: 2px;
    }
  }
  .wet-progress-step:not(:last-child) {
    margin-right: .5rem;
  }
  .wet-progress-step.active {
    background-position-x: 1%;
  }
  .wet-header-message {
    margin-top: 15px;
    margin-bottom: -16px;
  }
  @media only screen and (max-width: 1200px) {
    .wet-header-message {
      margin-top: 10px;
      margin-bottom: -11px;
    }
  }

  .menu-item-has-children ul.sub-menu {
    display: none;
    padding: 0.5rem 1rem 0 0;
    list-style: none;
    position: absolute;
    margin: 0px 0px;
    border-radius: 0.5rem;
  }

  .menu-item-has-children ul.sub-menu li {
    background-color: white;
    margin-left: -1rem;
    margin-right: -1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    min-width: 100%;
    border-left: 1px solid var(--gray-light);
    border-right: 1px solid var(--gray-light);
    box-sizing: content-box;
  }

  .menu-item-has-children ul.sub-menu li:first-child {
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem;
    padding-top: .5rem;
    border-top: 1px solid var(--gray-light);
  }

  .menu-item-has-children ul.sub-menu li a {
    display: block;
    white-space: nowrap;
  }

  .menu-item-has-children ul.sub-menu li:last-child {
    border-bottom-left-radius: .5rem;
    border-bottom-right-radius: .5rem;
    padding-bottom: .5rem;
    border-bottom: 1px solid var(--gray-light);
  }

  .menu-item-has-children ul.sub-menu:hover {
    display: block;
  }

  .menu-item-has-children:hover ul.sub-menu {
    display: block;
  }
</style>
