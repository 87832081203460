<template>
  <div>
    <ul class="list-unstyled border-y-between wet-arrow-right">
      <li v-for="(item, i) in menu" :key="i" class="mb-0">
        <router-link v-if="item.href" class="p-3 w-100" :exact="item.exact" :to="item.href">
          <div>{{item.title}}</div>
          <div class="flex align-items-center">
            <div
              v-if="item.icon"
              class="mr-2 wet-icon text-secondary"
              v-html="icons[item.icon]"
            ></div>
            <div class="mr-2 wet-icon-large" v-if="item.img">
              <img :src="item.img" alt="">
            </div>
            <div class="wet-note font-weight-normal">{{item.subtitle}}</div>
          </div>
        </router-link>
        <div v-else class="p-3 w-100" @click="item.event" style="cursor: pointer;">
          <div>{{item.title}}</div>
          <div class="flex align-items-center">
            <div v-if="item.icon" class="mr-2 wet-icon text-secondary" v-html="item.icon"></div>
            <div class="mr-2 wet-icon-large" v-if="item.img">
              <img :src="item.img" alt="">
            </div>
            <div class="wet-note font-weight-normal">{{item.subtitle}}</div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {
      icons: {
        personal: `
<svg viewBox="0 0 16 18">
<path d="M 8,1.9 C 9.16,1.9 10.1,2.84 10.1,4 10.1,5.16 9.16,6.1 8,6.1 6.84,6.1 5.9,5.16 5.9,4 5.9,2.84 6.84,1.9 8,1.9 m 0,9 c 2.97,0 6.1,1.46 6.1,2.1 v 1.1 H 1.9 V 13 C 1.9,12.36 5.03,10.9 8,10.9 M 8,0 C 5.79,0 4,1.79 4,4 4,6.21 5.79,8 8,8 10.21,8 12,6.21 12,4 12,1.79 10.21,0 8,0 Z M 8,9 C 5.33,9 0,10.34 0,13 v 3 H 16 V 13 C 16,10.34 10.67,9 8,9 Z"/>\
</svg>\
        `,
        company: `
<svg id="icon-map" viewBox="0 0 24 24">\
<path d="M15.535 21.887l-7.504-3.754-6.535 3.734A.998.998 0 0 1 0 21V5c0-.36.191-.691.504-.867L7.488.14A.986.986 0 0 1 8 0c.168 0 .328.043.465.113l7.504 3.754L22.504.133A.998.998 0 0 1 24 1v16c0 .36-.191.691-.504.867l-6.984 3.992A.986.986 0 0 1 16 22c-.168 0-.328-.043-.465-.113zM15 5.617l-6-3v13.766l6 3zm2-.035v13.695l5-2.859V2.723zM7 2.722l-5 2.86v13.695l5-2.859zm0 0" />
</svg>\
        `,
        bank: `
<svg viewBox="0 0 20 22">\
<path fill="currentColor" d="M 18,1.9837138 H 2 c -1.11,0 -1.99,0.89 -1.99,2 L 0,15.983714 c 0,1.11 0.89,2 2,2 h 16 c 1.11,0 2,-0.89 2,-2 V 3.9837138 c 0,-1.11 -0.89,-2 -2,-2 z M 18,15.983714 H 2 V 9.9837136 H 18 Z M 18,5.9837138 H 2 v -2 h 16 z"/>\
</svg>\
        `,
      },
    };
  },
  computed: {
    ...mapState(['strings']),
    ...mapState({
      menu: (state) => state.settings.menu,
    }),
  },
  methods: {
  },
};
</script>
<style lang="scss">
  // .wet-arrow-right {

  // }
</style>
