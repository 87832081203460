const availableWeatherDirs = {
  rainy: 'rainy',
  norain: 'norain',
  heat: 'heat',
  cold: 'cold',
  nosnow: 'nosnow',
  snowy: 'snowy',
};
const availableWeatherTypes = {
  rain: 'rain',
  temperature: 'temperature',
  snow: 'snow',
};
const availableProductPeriod = {
  hourly: 'hourly',
  daily: 'daily',
};
const errorStrings = {
  invalidUser: 'Invalid user',
  outsideRisk: 'The contract is outside of the risk boundaries',
  noContractInRiskBoundaries: 'No contract in risk boundaries found',
  toMuchDataMissing: 'Too much data is missing for the contract',
  stationsAreToAreAway: 'stations are to fare away',
  emailAlreadyExists: 'That email address is already occupied',
  orderNotPaidYet: 'order has not been paid yet',
  invalidPartnerCode: 'The selected partner code is invalid.',
  unknownCouponCode: 'the coupon code is unknown',
  usedCouponCode: 'the coupon code is already used',
  productNotActive: 'The product is not active',
};

const countryMap = {
  /* it for convert ISO 3166-1 Alpha-2 country code to country string */
  de: 'germany',
  ru: 'russia',
  pl: 'poland',
};

const availablePayoutInput = [
  'daily_payout', 'maximal_payout',
];

export {
  availableWeatherDirs,
  availableWeatherTypes,
  errorStrings,
  availableProductPeriod,
  countryMap,
  availablePayoutInput,
};
