<template>
  <div class="wet-loading-wrapper">
  <!-- eslint-disable max-len vue/max-len -->
    <div class="wet-loading-content" :id="Id">
      <div>
        <svg width="70px" height="70px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" class="preloader__svg" style="background: none;">
          <circle cx="50" cy="50" fill="none" stroke-linecap="round" r="40" stroke-width="4" stroke-dasharray="62.83185307179586 62.83185307179586" transform="rotate(257.961 50 50)">
          </circle>
        </svg>
      </div>
      <div v-if="loading.some((el) => el.message)" class="wet-loading-text">
        {{loading.filter((el) => el.message).map((el) => el.message).join('. ')}}
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';

export default {
  props: {
    Id: String,
  },
  computed: {
    ...mapState(['loading']),
  },
};
</script>
<style lang="scss">
  .wet-loading-wrapper {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
  }
  .wet-loading-content {
    width: 128px;
    height: 128px;
    padding: .5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: .5rem;
    box-shadow:
      .1rem .1rem .1rem rgba(0,0,0,.1),
      0 0 1rem rgba(0,0,0,.1);

    img {
      flex: 1;
      max-width: 55%;
    }

    div {
      // flex: none;
      text-align: center;
      font-size: .75rem;
    }
  }
</style>
