<template>
  <div
    class="wet-header-message wet-note p-1 p-sm-3 text-center wet-header-message"
  >
    <span class="mr-1">{{strings.commonApp && strings.commonApp.header_login_text}}</span>
    <router-link
      :to="headerLoginLink"
      id="wet-topbar-login-link"
      class="text-secondary"
    >
      {{strings.commonApp && strings.commonApp.header_login_link_title}}
    </router-link>
  </div>
</template>
<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState(['strings']),
    headerLoginLink() {
      const link = {
        name: 'login',
        params: { ...this.$route.params },
      };
      if (['letus'].includes(this.$route.name)) link.query = { redirect: 'done' };
      if (['additional'].includes(this.$route.name)) link.query = { redirect: 'additional' };
      if (['buy-vacation'].includes(this.$route.name)) link.query = { redirect: 'buy-vacation' };
      return link;
    },
  },
};
</script>
