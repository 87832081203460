import { camelCase } from 'lodash';
import metaData from '@/enums/websiteMeta';
import LogService from '@/services/LogService';

export default {
  data() {
    return {
      title: '',
      description: '',
      keywords: '',
      meta: [],
      searchBotIndex: false,
    };
  },
  methods: {
    setMeta(lang) {
      LogService.log('route', this.$route);
      LogService.log('route name', this.$route.name);
      const name = camelCase(this.$route.name);
      LogService.log('meta name', name);
      LogService.log('setMeta lang', lang);
      LogService.log('metaData', metaData, metaData[name]);
      const metaEntry = metaData[name][lang];

      LogService.log('metaEntry', metaEntry);
      const titleEnd = metaData.titleEnd[lang];
      this.title = metaEntry.title === '' ? titleEnd : `${metaEntry.title} - ${titleEnd}`;
      LogService.log('setMeta title', this.title);
      this.meta = [
        { property: 'og:title', content: this.title },
        { property: 'og:url', content: 'window' in global ? window.location.href : '' },
      ];
      if (metaEntry.keywords !== undefined) {
        this.meta.push({ name: 'keywords', content: metaEntry.keywords });
        this.meta.push({ property: 'og:keywords', content: metaEntry.keywords });
      }
      if (metaEntry.description !== undefined) {
        this.meta.push({ name: 'description', content: metaEntry.description });
        this.meta.push({ property: 'og:description', content: metaEntry.description });
      }
      LogService.log('this.meta', this.meta);
      this.$store.commit('SET_META', { meta: this.meta, title: this.title });
    },
  },
};
