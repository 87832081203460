<template>
  <transition name="fade-modal">
    <div
      class="modal modal-bg modal-open show"
      v-if="showed"
      tabindex="-1"
      @click.prevent.self="$emit('update:showed', false)"
    >

        <div class="modal-dialog">
          <div class="modal-content" >
            <div class="modal-header">
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-hidden="true"
                @click.prevent="$emit('update:showed', false)"
              >&times;</button>
              <h2 class="modal-title" id="myModalLabel">{{modtitle}}</h2>
            </div>
            <div class="modal-body">
              <slot></slot>
            </div>
          </div>
        </div>
    </div>
  </transition>
</template>
<script>
export default {
  props: {
    showed: Boolean,
    modtitle: String,
  },
};
</script>
<style lang="scss">
  .fade-modal-enter.modal.show .modal-dialog, .fade-modal-leave-to.modal.show .modal-dialog {
    transform: translate(0, -150px) rotateX(10deg);
  }

  .modal.show .modal-dialog {
    transition: all ease-in-out .3s;
    transform: translate(0, 0px);

  }

  .modal {
    padding-right: 15px;
    display: block;
    overflow-x: hidden;
    overflow-y: auto;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100%;
    height: 100%;
    outline: 0;
    perspective: 500px;
  }
  .modal-bg {
    background-color: rgba(0, 91, 170, 0.8);
  }
  @media screen and (max-width: 576px) {
    .modal {
        padding-left: 10px!important;
        padding-right: 10px!important;
    }
  }
</style>
