<template>
  <div>
    <router-view />
    <div class="wet-section wet-logos-slider wet-organizations" >
      <div class="container">
        <logosSlider
          :items="stringsSections.partners_images"
          name="partners"
          image-index-name="partners_images_image"
          link-index-name="partners_images_link"
        />
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-sm">
          <reactive-picture
            v-if="stringsSections.about_image.sizes"
            :image="stringsSections.about_image"
            :width=496
            :height=712
            :size-collection-type="wpImageSizeCollectionType.BIG"
            />
          <img
            v-else
            :width=496
            :height=712
          />
        </div>
        <div class="col-sm mt-5" v-html="stringsSections.about_description"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import wpImageSizeCollectionType from '@/enums/wpImageSizeCollectionType';

export default {
  data() {
    return {
      wpImageSizeCollectionType,
    };
  },
  components: {
    logosSlider: () => import(/* webpackChunkName: "logosSlider" */ '@/components/logosSlider.vue'),
    reactivePicture: () => import(/* webpackChunkName: "wp-picture" */ '@/components/ui/reactive-pictureUi.vue'),
  },
  computed: {
    ...mapState(['strings', 'lang']),
    stringsSections() {
      return this.strings.sections || {
        about_image: {
          image: {},
        },
        partners_images: [
          {
            partners_images_image: {},
          },
          {
            partners_images_image: {},
          },
          {
            partners_images_image: {},
          },
        ],
      };
    },
  },
  watch: {
  },
  methods: {
  },
};
</script>
<style lang="scss">
</style>
