<template>
  <div
    class="wet-header-message p-1 p-sm-3 text-center"
    :class="{
      'text-primary': highlight,
      'wet-note': !highlight,
      'font-weight-bold': highlight,
    }"
  >
    {{title}}
  </div>
</template>
<script>
export default {
  props: {
    highlight: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    strings() {
      return this.$store.state.strings;
    },
    title() {
      return this.strings.location?.[this.$route.name === 'location' ? 'place_placeholder' : 'place_placeholder_crop'];
    },
  },
};
</script>
