import Vue from 'vue';
import VueRouter from 'vue-router';

import AppLayout from '@/layouts/AppLayout.vue';
import PurchaseLayout from '@/layouts/PurchaseLayout.vue';
import LandingLayout from '@/layouts/LandingLayout.vue';
import LandingIndex from '@/views/landing/indexLanding.vue';
import Success from '@/views/SuccessView.vue';
import Login from '@/views/LoginView.vue';
import LogoutView from '@/views/LogoutView.vue';
import Signup from '@/views/SignupView.vue';
import Letus from '@/views/LetusView.vue';
import CreateNewVacationContract from '@/views/landing/CreateNewVacationContractLanding.vue';
import store from '@/store';
import MainLowerPart from '@/views/landing/MainLowerPartLanding.vue';
import configEnv from '@/enums/configEnv';

const disabledPages = process.env.VUE_APP_DISABLED_PAGES?.split(',').filter((p) => !!p) || [];
const enabledPages = process.env.VUE_APP_ENABLED_PAGES?.split(',').filter((p) => !!p) || [];
const altDrought = process.env.VUE_APP_DROUGHT_PAGE_VERSION === 'order-bundle';
const langs = configEnv.allowed_langs;

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/HomeView'),
  },
  {
    path: '/home/logout',
    name: 'logout',
    component: LogoutView,
  },
  {
    path: `/home/:lang(${langs})?/create-new-vacation-contract`,
    name: 'create-new-vacation-contract',
    component: CreateNewVacationContract,
  },
  {
    path: `/home/:lang(${langs})?/`,
    component: LandingLayout,
    children: [
      {
        path: '',
        component: LandingIndex,
        children: [
          {
            path: '',
            component: MainLowerPart,
            children: [
              {
                path: '',
                name: 'main',
                component: () => import(/* webpackChunkName: "hotelPage" */ '@/views/landing/MainLanding.vue'),

              },
            ],
          },
        ],
      },
      {
        path: '',
        component: LandingIndex,
        children: [
          {
            path: 'vacation',
            name: 'vacation',
            component: () => import(/* webpackChunkName: "vacationPage" */ '@/views/landing/VacationLanding.vue'),
          },
          {
            path: 'regenversicherung-urlaub',
            name: 'regenversicherung-urlaub',
            component: () => import(/* webpackChunkName: "vacationPage" */ '@/views/landing/VacationLanding.vue'),
          },
          {
            path: 'dertour-regenversicherung',
            name: 'dertour-regenversicherung',
            component: () => import(/* webpackChunkName: "vacationCouponDerTourLanding" */ '@/views/landing/VacationCouponDerTourLanding.vue'),
          },
          {
            path: 'hotel',
            name: 'hotel',
            component: () => import(/* webpackChunkName: "hotelPage" */ '@/views/landing/HotelLanding.vue'),
          },
          ...(!altDrought ? [{
            path: 'drought',
            name: 'drought',
            component: () => import(/* webpackChunkName: "droughtPage" */ '@/views/DroughtView.vue'),
          }] : []),
          {
            path: 'rain',
            name: 'rain',
            component: () => import(/* webpackChunkName: "rainPage" */ '@/views/RainView.vue'),
          },
          {
            path: 'custom',
            name: 'custom',
            component: () => import(/* webpackChunkName: "customPage" */ '@/views/CustomView.vue'),
          },
          {
            path: 'drought_no_commitment',
            name: 'drought2',
            component: () => import(/* webpackChunkName: "drought_no_commitmentPage" */ '@/views/Drought2View.vue'),
          },
        ],
      },
    ],
  },
  {
    path: `/home/:lang(${langs})?/`,
    name: 'app',
    component: AppLayout,
    children: [
      {
        path: 'settings',
        name: 'settings',
        component: () => import(/* webpackChunkName: "Settings" */ '@/views/SettingsView.vue'),
      },
      {
        path: 'insurance',
        name: 'insurance',
        component: () => import(/* webpackChunkName: "Insurance" */ '@/views/InsuranceView.vue'),
      },
      {
        path: 'insurance/:id/',
        name: 'insurance-id',
        component: () => import(/* webpackChunkName: "InsuranceId" */ '@/views/InsuranceIdView.vue'),
      },
      {
        path: 'drought-risk',
        name: 'drought-risk',
        component: () => import(/* webpackChunkName: "DroughtRisk" */ '@/views/DroughtRiskView.vue'),
      },
      {
        path: 'crop-selection',
        name: 'crop-selection',
        component: () => import(/* webpackChunkName: "CropSelection" */ '@/views/CropSelectionView.vue'),
      },
      ...(altDrought ? [{
        path: 'drought',
        name: 'drought',
        component: () => import(/* webpackChunkName: "Drought-bundle" */ '@/views/DroughtBundleView.vue'),
      }] : []),
      {
        path: 'crop-location',
        name: 'crop-location',
        component: () => import(/* webpackChunkName: "CropLocation" */ '@/views/CropLocation.vue'),
      },
      {
        path: 'done-bundle',
        name: 'done-bundle',
        component: () => import(/* webpackChunkName: "DoneBundle" */ '@/views/DoneBundle.vue'),
      },
      {
        path: '404',
        name: '404',
        component: () => import(/* webpackChunkName: "404" */ '@/views/404View.vue'),
      },
    ],
  },
  {
    path: `/home/:lang(${langs})?/`,
    name: 'app-purchase',
    component: PurchaseLayout,
    children: [
      {
        path: 'location',
        name: 'location',
        component: () => import(/* webpackChunkName: "Location" */ '@/views/LocationView.vue'),
      },
      {
        path: 'done',
        name: 'done',
        component: () => import(/* webpackChunkName: "Done" */ '@/views/DoneView'),
      },
      {
        path: 'additional',
        name: 'additional',
        component: () => import(/* webpackChunkName: "Additional" */ '@/views/AdditionalView.vue'),
      },
      {
        path: 'buy-vacation',
        name: 'buy-vacation',
        component: () => import(/* webpackChunkName: "BuyVacation" */ '@/views/BuyVacationView.vue'),
      },
      {
        path: 'finalize-vacation',
        name: 'finalize-vacation',
        component: () => import(/* webpackChunkName: "BuyVacation" */ '@/views/FinalizeVacationView.vue'),
      },
      {
        path: 'buy',
        name: 'buy',
        component: () => import(/* webpackChunkName: "Buy" */ '@/views/BuyView.vue'),
      },
      {
        path: 'login',
        name: 'login',
        component: Login,
      },
      {
        path: 'signup',
        name: 'signup',
        component: Signup,
      },
      {
        path: 'success',
        name: 'success',
        component: Success,
      },
      {
        path: 'letus',
        name: 'letus',
        component: Letus,
      },
      {
        path: 'forgot',
        name: 'forgot',
        component: () => import(/* webpackChunkName: "Forgot" */ '@/views/ForgotView.vue'),
      },
      {
        path: 'password-reset',
        name: 'password-reset',
        component: () => import(/* webpackChunkName: "PasswordReset" */ '@/views/PasswordResetView.vue'),
      },
    ],
  },
  {
    path: '*',
    redirect: (to) => {
      window.location = `/home/${to.params?.lang || ''}`;
    },
  },
];

const router = new VueRouter({
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  let resolved;

  // set current lang if route changed
  store.dispatch('updLang', to.params.lang || 'en');

  // check for disabled
  if (enabledPages.length === 0 && disabledPages.includes(to.name)) {
    window.location.href = process.env.VUE_APP_WP_API_SERVER;
    return;
  }

  // check for enabled
  if (enabledPages.length > 0 && !enabledPages.includes(to.name)) {
    window.location.href = process.env.VUE_APP_WP_API_SERVER;
    return;
  }

  next(resolved);
});

router.afterEach((to) => {
  if ('parentIFrame' in window) {
    // disable autoresize
    window.parentIFrame.autoResize(false);
    setTimeout(() => {
      // set height to zero
      window.parentIFrame.size(0);
    }, 50);
    // enable autoresize
    setTimeout(() => {
      window.parentIFrame.autoResize(true);
      // send the message for update
      window.parentIFrame.sendMessage({
        type: 'route',
        message: to.path,
      });
    }, 400);
  }
});

export default router;
