const wpSection = {
  BUY: 'buy',
  BUY_VACATION: 'buy-vacation',
  COMMON_APP: 'common-app',
  FINALIZE_VACATION: 'finalize-vacation',
  ADDITIONAL: 'additional',
  SETTINGS: 'settings',
  VACATION: 'vacation',
  LOCATION: 'location',
  DONE: 'done',
  SECTIONS: 'sections',
  DROUGHT: 'drought',
  DROUGHT_RISK: 'drought-risk',
  RAIN: 'rain',
  CUSTOM: 'custom',
  HOTEL: 'hotel',
  SUCCESS: 'success',
  LOGIN: 'login',
  PASSWORD_RESET: 'password-reset',
  PASSWORD_SET: 'password-set',
  LETUS: 'letus',
  FORGOT: 'forgot',
  CROP_SELECTION: 'crop-selection',
  PAGE404: 'page404',
  INSURANCE: 'insurance',
  PARTNER_DESIGN: 'partner-design',
};

export default wpSection;
