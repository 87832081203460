import {
  required,
  numeric,
  minLength,
  maxLength,
  sameAs,
  helpers,
} from 'vuelidate/lib/validators';
import * as $iban from 'iban';

const phone = {
  required,
  numeric,
  minLength: minLength(4),
};
const firstname = {
  required,
  minLength: minLength(2),
  maxLength: maxLength(50),
  text: (val) => /^[a-zA-ZÀ-ž]+((['.-\s][\s]?[a-zA-ZÀ-ž])?[a-zA-ZÀ-ž]*)*$/.test(val?.toString()),
};
const coupon_code_der_tour = {
  required,
  minLength: minLength(8),
};
const agency_id_1_to_6 = {
  minLength: minLength(1),
  maxLength: maxLength(6),
};

const agency_id_1_to_20 = {
  minLength: minLength(1),
  maxLength: maxLength(20),
};
const zip = {
  required,
  minLength: minLength(1),
};

const city = {
  required,
  minLength: minLength(1),
};

const houseNo = {
  required,
  minLength: minLength(1),
};

const lastname = {
  required,
  minLength: minLength(2),
  maxLength: maxLength(50),
  text: (val) => /^[a-zA-ZÀ-ž]+((['.-\s][\s]?[a-zA-ZÀ-ž])?[a-zA-ZÀ-ž]*)*$/.test(val?.toString()),
};
const notEmpty = {
  required,
  minLength: minLength(1),
};
const password = {
  required,
  minLength: minLength(8),
  hasOneNum: (val) => !helpers.req(val) || /.*\d{1}.*/.test(val?.toString()),
  hasOneLetter: (val) => !helpers.req(val) || /.*[A-Za-z]{1}.*/.test(val?.toString()),
};
const passwordRetype = {
  required,
  sameAs: sameAs('password'),
};
const companyName = {
  required,
  minLength: minLength(3),
};
const iban = {
  required,
  iban: $iban.isValid,
};

const EuIbanLetters = ['AT', 'BE', 'BG', 'HR', 'CY', 'CZ', 'DK', 'EE', 'FI', 'FR', 'DE', 'GR', 'HU',
  'IE', 'IT', 'LV', 'LT', 'LU', 'MT', 'PL', 'PT', 'RO', 'SK', 'SI', 'ES', 'SE', 'NL'];

const ChLiLetters = ['CH', 'LI'];

const hasEuIbanLetters = (value) => {
  let found = false;
  EuIbanLetters.forEach((letters) => {
    if (value.toUpperCase().includes(letters)) {
      found = true;
    }
  });
  return found;
};

const hasChLiIbanLetters = (value) => {
  let found = false;
  ChLiLetters.forEach((letters) => {
    if (value.toUpperCase().includes(letters)) {
      found = true;
    }
  });
  return found;
};

const euIban = {
  required,
  iban: $iban.isValid,
  hasEuIbanLetters,
};

const chLiIban = {
  required,
  iban: $iban.isValid,
  hasChLiIbanLetters,
};

export {
  phone,
  firstname,
  lastname,
  password,
  passwordRetype,
  companyName,
  iban,
  euIban,
  chLiIban,
  agency_id_1_to_6,
  agency_id_1_to_20,
  zip,
  houseNo,
  city,
  notEmpty,
  coupon_code_der_tour,
};
