import Moment from 'moment';
import { extendMoment } from 'moment-range';

const moment = extendMoment(Moment);

moment.lenInterval = (dates) => {
  /**
   * Calc interval between nim and max in Array of Moment.
   */
  const min = moment.min(dates);
  const max = moment.max(dates);
  return max.diff(min, 'days');
};

moment.today = () => moment({
  h: 0,
  m: 0,
  s: 0,
  ms: 0,
});

export default moment;
