<template>
  <div class="d-flex flex-column justify-content-center">
    <div class="wet-section">
      <div class="container">
        <div class="wet-section-title text-center">
          <h1 class="empty-ph">
            {{strings.login && strings.login.welcome_registration}}
          </h1>
        </div>
        <div class="row justify-content-center">
          <div class="col-12 col-sm-8 col-lg-5">
            <form @submit.prevent="submit">
              <div class="wet-input wet-input-with-label">
                <input
                  id="wet-input-email"
                  type="text"
                  :placeholder="strings.login && strings.login.email"
                  v-model.trim="$v.form.email.$model"
                >
                <label for="wet-input-email">
                  {{ strings.login && strings.login.email }}
                </label>
              </div>
              <div class="wet-input wet-input-with-label">
                <input
                  id="wet-input-password"
                  type="password"
                  :placeholder="strings.login && strings.login.password"
                  v-model="$v.form.password.$model"
                >
                <label for="wet-input-password">
                  {{ strings.login && strings.login.password }}
                </label>
              </div>
              <div class="wet-input wet-input-with-label">
                <input
                  id="wet-input-password-retype"
                  type="password"
                  :placeholder="strings.login && strings.login.password_retype"
                  v-model="$v.form.passwordRetype.$model"
                >
                <label for="wet-input-password-retype">
                  {{ strings.login && strings.login.password_retype }}
                </label>
              </div>
              <div
                v-for="(error, id) in errors" :key="id"
                class="small text-primary mt-n2 mb-3 mx-3"
              >
                {{ error }}
              </div>
              <div class="d-flex">
                <button
                  id="back-button"
                  type="button"
                  class="wet-button wet-button-flat empty-ph"
                  style="min-width:4rem;"
                  @click.prevent="back"
                >{{strings.commonApp && strings.commonApp.back_text}}</button>
                <button
                  id="wet-button-signup"
                  type="submit"
                  class="wet-button empty-ph ml-3 flex-fill"
                  :disabled="disabled"
                >{{strings.login && strings.login.button_signup}}</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import common from '@/mixins/common';
import {
  required,
  email,
} from 'vuelidate/lib/validators';
import {
  password,
  passwordRetype,
} from '@/common/validations';
import wpSection from '@/enums/wpSection';

export default {
  mixins: [common],
  data() {
    return {
      signupApi: '/api/user/register',
      form: {
        email: '',
        password: '',
        passwordRetype: '',
      },
      icons: {
        fb: `
        <svg viewBox="0 0 24 24">
          <path fill="currentColor" d="M12 2.04C6.5 2.04 2 6.53 2 12.06C2 17.06 5.66 21.21 10.44 21.96V14.96H7.9V12.06H10.44V9.85C10.44 7.34 11.93 5.96 14.22 5.96C15.31 5.96 16.45 6.15 16.45 6.15V8.62H15.19C13.95 8.62 13.56 9.39 13.56 10.18V12.06H16.34L15.89 14.96H13.56V21.96A10 10 0 0 0 22 12.06C22 6.53 17.5 2.04 12 2.04Z" />
        </svg>`,
        google: `
        <svg viewBox="0 0 24 24">
          <path fill="currentColor" d="M21.35,11.1H12.18V13.83H18.69C18.36,17.64 15.19,19.27 12.19,19.27C8.36,19.27 5,16.25 5,12C5,7.9 8.2,4.73 12.2,4.73C15.29,4.73 17.1,6.7 17.1,6.7L19,4.72C19,4.72 16.56,2 12.1,2C6.42,2 2.03,6.8 2.03,12C2.03,17.05 6.16,22 12.25,22C17.6,22 21.5,18.33 21.5,12.91C21.5,11.76 21.35,11.1 21.35,11.1V11.1Z" />
        </svg>`,
      },
      prevRoute: null,
      emailExists: false,
    };
  },
  computed: {
    ...mapState(['strings', 'loading']),
    disabled() {
      return this.$v.$anyError || Object.values(this.form).some((el) => !el)
        || this.loading.length > 0;
    },
    errors() {
      const errors = [];
      if (!this.$v.form.email.exists) {
        errors.push(this.strings.commonApp?.error_email_already_used);
      } else if (this.$v.form.email.$error) errors.push(this.strings.login?.error_email);
      if (!this.$v.form.passwordRetype.sameAs) {
        errors.push(this.strings.login?.error_password_identical);
      }
      if (!this.$v.form.password.hasOneNum
        || !this.$v.form.password.minLength
        || !this.$v.form.password.hasOneLetter) {
        errors.push(this.strings.login?.error_password_rules);
      } else if (this.$v.form.password.$error) errors.push(this.strings.login?.error_password);
      return errors;
    },
  },
  validations() {
    return {
      form: {
        email: {
          required,
          email,
          exists: () => !this.emailExists,
        },
        password,
        passwordRetype,
      },
    };
  },
  watch: {
    'form.email': {
      handler() {
        this.emailExists = false;
      },
    },
  },
  async created() {
    await this.getWordPressStrings([wpSection.LOGIN], wpSection.LOGIN, 'title_alt');
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$set(vm, 'prevRoute', from);
    });
  },
  methods: {
    async submit() {
      this.$v.$touch();
      if (this.$v.$anyError) return;
      this.emailExists = false;
      const data = {
        email: this.form.email,
        password: this.form.password,
      };
      // let res = '';
      await this.callApi({ url: this.signupApi, method: 'post', data })
        .then(() => {
          const defaultRoute = 'home';
          const name = this.$route.query?.redirect || defaultRoute;
          this.$router.push({ name, params: { ...this.$route.params } });
        })
        .catch((error) => {
          if (error.response?.data?.errors?.email?.[0] === 'This email is already in use.') {
            this.emailExists = true;
          }
        });
    },
    back() {
      // if (this.prevRoute?.name === 'additional') {
      this.$router.back();
      // } else window.location = '/webapp';
    },
  },
};
</script>
