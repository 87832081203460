<template>
  <div role="form" class="wpcf7" id="wpcf7-f170-o1" lang="en-US" dir="ltr">
    <div class="screen-reader-response"></div>
    <form
      action="/home/drought/#wpcf7-f170-o1"
      method="post"
      class="wpcf7-form mailchimp-ext-0.5.17"
      novalidate="novalidate"
    >
      <div style="display: none;">
        <input type="hidden" name="_wpcf7" value="170" />
        <input type="hidden" name="_wpcf7_version" value="5.1.7" />
        <input type="hidden" name="_wpcf7_locale" value="en_US" />
        <input type="hidden" name="_wpcf7_unit_tag" value="wpcf7-f170-o1" />
        <input type="hidden" name="_wpcf7_container_post" value="0" />
        <input type="hidden" name="lang" value="en" />
        <input type="hidden" name="g-recaptcha-response" value="" />
      </div>
      <p>
        <span class="wpcf7-form-control-wrap cemail">
          <input
            type="email"
            name="cemail"
            value=""
            size="40"
            class="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required"
            aria-required="true"
            aria-invalid="false"
            placeholder="Your E-Mail"
          />
        </span>
        <br />
        <span class="wpcf7-form-control-wrap phone">
          <input
            type="text"
            name="phone"
            value=""
            size="40"
            class="wpcf7-form-control wpcf7-text"
            aria-invalid="false"
            placeholder="Your phone number"
          />
        </span>
        <br />
        <span class="wpcf7-form-control-wrap cname">
          <input
            type="text"
            name="cname"
            value=""
            size="40"
            class="wpcf7-form-control wpcf7-text"
            aria-invalid="false"
            placeholder="Your Name"
          />
        </span>
        <br />
        <span class="wpcf7-form-control-wrap cmassage">
          <textarea
            name="cmassage"
            cols="40"
            rows="10"
            class="wpcf7-form-control wpcf7-textarea"
            aria-invalid="false"
            placeholder="Your message"
          ></textarea>
        </span>
      </p>
      <div class="wpcf7-form-control-wrap">
        <div
          data-sitekey="6LcF0JkUAAAAAOuFqcM49VCzLozoYEChzV0dsmn-"
          class="wpcf7-form-control g-recaptcha wpcf7-recaptcha"
        ></div>
      </div>
      <p>
        <input type="submit" value="Send" class="wpcf7-form-control wpcf7-submit send" />
      </p>
      <div class="wpcf7-response-output wpcf7-display-none"></div>
      <p style="display: none !important">
        <span class="wpcf7-form-control-wrap referer-page">
          <input
            type="hidden"
            name="referer-page"
            :value="wpApi"
            class="wpcf7-form-control wpcf7-text referer-page"
            aria-invalid="false"
          >
        </span>
      </p>
    </form>
  </div>
</template>
<script>
export default {
  data() {
    return {
      wpApi: process.env.VUE_APP_WP_API_SERVER,
    };
  },
};
</script>
