import axios from 'axios';
import LogService from '@/services/LogService';
import EnvService from '@/services/EnvService';
import { errorStrings } from '@/constants';

class WebAppAPIService {
  constructor(store) {
    this.store = store;
  }

  createAxiosInstance() {
    return axios.create({
      baseURL: process.env.VUE_APP_API_SERVER,
      headers: {
        Authorization: process.env.VUE_APP_API_KEY,
        token: this.store.state.token ?? '',
        lang: this.store.state.lang,
      },
    });
  }

  setToken(token) {
    this.store.commit('SET_TOKEN', token, { root: true });
  }

  showErrorPopUp(method, url, status) {
    this.showErrorPopUpMessageOnly(`METHOD ${method} URL of API ${url} return status code ${status}`);
  }

  showErrorPopUpMessageOnly(message) {
    // show error message
    this.store.commit(
      'ADD_DEV_MESSAGE',
      message, { root: true },
    );
    this.store.commit('RESET_LOADING');
  }

  defaultErrorHandling(err, method, url) {
    LogService.log('API call error', method, url, err.response.status);
    LogService.log('default error handler');
    if (EnvService.localOrDev()) this.showErrorPopUp(method, url, err.response.status);
    throw Error('Api call error');
  }

  async callApiDefaultErrorHandling(config) {
    LogService.log('before axios Instance creation');
    const axiosInstance = this.createAxiosInstance();
    const res = await axiosInstance.request(config)
      .catch((err) => this.defaultErrorHandling(err, config.method, config.url));
    LogService.log('returned headers', res, res.headers?.token);
    const token = res.headers?.token || null;
    if (token) this.setToken(token);
    LogService.log(`${config.method} ${config.url} response`, res);
    return res;
  }

  async callApiPostProductErrorHandling(config) {
    LogService.log('before axios Instance creation');
    const axiosInstance = this.createAxiosInstance();
    return axiosInstance.request(config)
      .catch((err) => {
        LogService.log('error response', err.response);
        LogService.log(
          err.response?.data?.errors?.partner_code,
          Array.isArray(err.response?.data?.errors?.partner_code),
          errorStrings.invalidPartnerCode,
          err.response?.data?.errors?.partner_code.includes(errorStrings.invalidPartnerCode),
        );
        if (Array.isArray(err.response?.data?.errors?.partner_code)
          && err.response?.data?.errors?.partner_code.includes(errorStrings.invalidPartnerCode)) {
          throw Error(errorStrings.invalidPartnerCode);
        }
        this.defaultErrorHandling(err, config.method, config.url);
      });
  }

  async callApiPostProductCouponErrorHandling(config) {
    return this.throwSpecialErrorsHandling(
      config,
      [errorStrings.unknownCouponCode, errorStrings.usedCouponCode],
    );
  }

  async throwSpecialErrorsHandling(config, errorArray) {
    const axiosInstance = this.createAxiosInstance();
    return axiosInstance.request(config)
      .catch((err) => {
        const message = err?.response?.data?.message;
        if (typeof message !== 'undefined'
          && errorArray.includes(message)) {
          throw Error(message);
        }
        this.defaultErrorHandling(err, config.method, config.url);
      });
  }

  async callApiPostBuyErrorHandling(config) {
    return this.throwSpecialErrorsHandling(config, [errorStrings.productNotActive]);
  }

  async callApiNoErrorHandling(config) {
    const axiosInstance = this.createAxiosInstance();
    const res = await axiosInstance.request(config);
    const token = res.headers?.token || null;
    if (token) this.setToken(token);
    LogService.log(`${config.method} ${config.url} response`, res);
    return res;
  }

  async callApiPriceErrorHandling(config, showErrorMessage) {
    const axiosInstance = this.createAxiosInstance();
    const res = await axiosInstance.request(config)
      .catch((err) => {
        if ([
          errorStrings.outsideRisk,
          errorStrings.toMuchDataMissing,
          errorStrings.stationsAreToAreAway].includes(err.response?.data?.message)) {
          showErrorMessage();
        } else {
          this.defaultErrorHandling(err, config.method, config.url);
        }
      });
    const token = res.headers?.token || null;
    if (token) this.setToken(token);
    LogService.log(`${config.method} ${config.url} response`, res);
    return res;
  }

  async callApiCheckPaymentErrorHandling(config, showErrorMessage) {
    const axiosInstance = this.createAxiosInstance();
    const res = await axiosInstance.request(config)
      .catch((err) => {
        if (err.response?.data?.message === errorStrings.orderNotPaidYet) {
          showErrorMessage();
          throw err;
        } else {
          this.defaultErrorHandling(err, config.method, config.url);
        }
      });
    const token = res.headers?.token || null;
    if (token) this.setToken(token);
    LogService.log(`${config.method} ${config.url} response`, res);
    return res;
  }

  async callPutUserProfilePaymentErrorHandling(config) {
    const axiosInstance = this.createAxiosInstance();
    const res = await axiosInstance.request(config)
      .catch((err) => {
        if (err.response?.data?.message === errorStrings.emailAlreadyExists) {
          throw err;
        } else {
          this.defaultErrorHandling(err, config.method, config.url);
        }
      });
    const token = res.headers?.token || null;
    if (token) this.setToken(token);
    LogService.log(`${config.method} ${config.url} response`, res);
    return res;
  }

  async callResetPassword(data) {
    const method = 'post';
    const url = '/api/user/reset_password';
    return this.callApiDefaultErrorHandling({ method, url, data });
  }

  async callSetPassword(data, headers) {
    const method = 'post';
    const url = '/api/user/set_password';
    return this.callApiDefaultErrorHandling({
      method,
      url,
      data,
      headers,
    });
  }

  getOrder() {
    const method = 'get';
    const url = '/api/order/';

    return this.callApiDefaultErrorHandling({ method, url });
  }

  getUserInfo() {
    const method = 'get';
    const url = '/api/user/info';

    return this.callApiDefaultErrorHandling({ method, url });
  }

  getUserInfoUserOnly() {
    const method = 'get';
    const url = '/api/user/info_user_only';

    return this.callApiNoErrorHandling({ method, url });
  }

  getBuyInfo() {
    const method = 'get';
    const url = '/api/user/info_buy';

    return this.callApiDefaultErrorHandling({ method, url });
  }

  postClaim(data) {
    const method = 'post';
    const url = '/api/order/claim';

    return this.callApiDefaultErrorHandling({ method, url, data });
  }

  getBuyInfoOneScreen() {
    const method = 'get';
    const url = '/api/user/info_buy_one_screen';

    return this.callApiDefaultErrorHandling({ method, url });
  }

  postCreateStripeSession(fromIFrame = false) {
    const method = 'post';
    const url = '/api/order/create_stripe_session_vacation';
    if (fromIFrame) {
      return this.callApiDefaultErrorHandling(
        { method, url, data: { from_iframe: fromIFrame } },
      );
    }

    return this.callApiDefaultErrorHandling({ method, url });
  }

  checkPayoutStatus(data) {
    const method = 'post';
    const url = '/api/order/check_payout_status';
    return this.callApiNoErrorHandling({ method, url, data });
  }

  putUserProfilePayment(data) {
    const method = 'put';
    const url = '/api/user/data/update';
    return this.callPutUserProfilePaymentErrorHandling({ method, url, data });
  }

  postFindStation(data) {
    const method = 'post';
    const url = '/api/station/find';
    return this.callApiDefaultErrorHandling({ method, url, data });
  }

  postFindRiskAssessmentLocation(data) {
    const method = 'post';
    const url = '/api/risk_assessment/location';
    return this.callApiDefaultErrorHandling({ method, url, data });
  }

  postSaveSurvey(data) {
    const method = 'post';
    const url = '/api/vacation/save_survey_data';
    return this.callApiDefaultErrorHandling({ method, url, data });
  }

  getOptimalContract() {
    const method = 'get';
    const url = '/api/order/optimal_contract/';
    return this.callApiNoErrorHandling({ method, url });
  }

  postProduct(data, headers = undefined) {
    const method = 'post';
    const url = '/api/product';
    const config = { method, url, data };
    if (headers !== undefined) {
      config.headers = headers;
    }
    return this.callApiPostProductErrorHandling(config);
  }

  postProductWithLocation(data, headers = undefined) {
    const method = 'post';
    const url = '/api/product/with_location';
    const config = { method, url, data };
    if (headers !== undefined) {
      config.headers = headers;
    }
    return this.callApiPostProductErrorHandling(config);
  }

  postProductCoupon(data) {
    const method = 'post';
    const url = '/api/product/coupon';
    return this.callApiPostProductCouponErrorHandling({ method, url, data });
  }

  getSourceTypes(businessType) {
    const method = 'get';
    const url = `/api/product/source_types/${businessType}`;
    return this.callApiDefaultErrorHandling({ method, url });
  }

  postInvalidCountry(data) {
    const method = 'post';
    const url = '/api/vacation/invalid_country_error';
    return this.callApiDefaultErrorHandling({ method, url, data });
  }

  postPrice(daily_payout, risk_days, weather_severity, threshold = null) {
    const method = 'post';
    const url = '/api/order/price/';
    const data = threshold === null
      ? { daily_payout, risk_days, weather_severity }
      : { daily_payout, risk_days, threshold };
    return this.callApiNoErrorHandling({ method, url, data });
  }

  putSettings(data) {
    const method = 'put';
    const url = '/api/user/settings/';
    return this.callApiDefaultErrorHandling({ method, url, data });
  }

  postInsurerSelection(insurerId) {
    const method = 'post';
    const url = `/api/insurer/${insurerId.toString()}`;
    return this.callApiDefaultErrorHandling({ method, url });
  }

  postBuy() {
    const method = 'post';
    const url = '/api/order/buy';
    return this.callApiPostBuyErrorHandling({ method, url });
  }

  postHotelCreateSession(
    partner_code = undefined,
    name = undefined,
    address = undefined,
    zip = undefined,
    city = undefined,
    country = undefined,
    start_date = undefined,
    end_date = undefined,
    daily_payout = undefined,
    firstname = undefined,
    lastname = undefined,
    phone = undefined,
    agency_id = undefined,
    show_agency_id = undefined,
    cities_allowed = undefined,
    create_session = undefined,
    source = undefined,
    vstemp = undefined,
  ) {
    const data = {
      partner_code,
      name,
      address,
      zip,
      city,
      country,
      start_date,
      end_date,
      daily_payout,
      firstname,
      lastname,
      phone,
      agency_id,
      show_agency_id,
      cities_allowed,
      create_session,
      source,
    };

    Object.keys(data).forEach((index) => {
      // eslint-disable-next-line no-unused-expressions
      data[index] === undefined ? delete data[index] : {};
    });

    LogService.log('postHotelCreateSession data', data);

    const method = 'post';
    const url = '/api/vacation/create_session_from_hotel_data';
    const headers = { vstemp };
    return this.callApiNoErrorHandling({
      method, url, data, headers,
    });
  }

  postUpdateVacationField(field, value = undefined) {
    const method = 'post';
    const url = '/api/vacation/update_field';
    const data = { field };
    if (value !== undefined) {
      data.value = value;
    }

    return this.callApiPostProductErrorHandling({ method, url, data });
  }
}

export default WebAppAPIService;
