import { mapState } from 'vuex';
import { callApi } from '@/api';
import LogService from '@/services/LogService';
import { camelCase } from 'lodash';

const common = {
  computed: {
    ...mapState(['lang']),
    phoneCodes() {
      return [
        {
          code: '49',
          text: '+49',
          country: 'Deutschland',
          flag: '',
        },
      ];
    },
    routeLang() {
      return this.$route.params?.lang || '';
    },
  },
  methods: {
    async getWordPressStrings(sectionArray, pageTitleSection = undefined, selector = 'title', lang = undefined) {
      LogService.log('calling wp strings');
      await this.$wpAPI.getStringsArray(
        sectionArray,
        lang ?? this.routeLang,
      ).then((results) => {
        LogService.log('wp strings returned', results);
        this.str = results;
        if (pageTitleSection !== undefined) {
          const pageTitle = this.str[camelCase(pageTitleSection)][selector];
          this.$store.commit('SET_PAGE_TITLE', pageTitle);
          LogService.log('getWordPressStrings, set pageTitle', pageTitle);
        }
      });
    },
    async saveBeforeLeave() {
      // save changes in api
      const infoApi = '/api/order/';
      await this.callApi({ url: infoApi, method: 'post' });
    },
    callApi(o) {
      return callApi(o, this.$store);
    },
    checkIfRedirectToEnglishNecessary() {
      const { locale } = Intl.NumberFormat().resolvedOptions();
      const supportedLocales = navigator.languages;
      const { lang } = this.$route.params;
      const { just_arrived } = this.$route.query;
      const deSupported = supportedLocales.some((x) => x.startsWith('de'));
      if (just_arrived && locale !== undefined && locale.startsWith('en') && !deSupported && lang === 'de') {
        const { query } = this.$route;
        if ('just_arrived' in query) {
          delete query.just_arrived;
        }
        this.$router.replace({ name: this.$route.name, params: { ...this.$route.params, lang: 'en' }, query });
        return true;
      }
      return false;
    },
    format(template, ...args) {
      // formatting string such as 'lorem %s ispum' and replace %s to param from args
      let n = -1;
      return template && template.replace(/(%s)/g, () => {
        n += 1;
        return args[n];
      });
    },
  },
};

export default common;
