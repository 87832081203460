var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"position-relative"},[(_vm.hasDrawer)?_c('app-drawer',{attrs:{"drawer":_vm.drawer},on:{"update:drawer":function($event){_vm.drawer=$event},"close":function($event){_vm.drawer = false}}},[_c(_vm.drawerComponent,{tag:"component",attrs:{"menu":_vm.menu},on:{"click":function($event){_vm.drawer = false}}})],1):_vm._e(),_c('header-component',_vm._g(_vm._b({scopedSlots:_vm._u([{key:"message",fn:function(){return [_c('transition',{attrs:{"name":"collapse"}},[_c(_vm.headerMessageComponent,{tag:"component",attrs:{"highlight":['crop-location', 'location'].includes(_vm.$route.name)}})],1)]},proxy:true}])},'header-component',_vm.headerAttrs,false),_vm.headerEvents)),_c('main',{staticClass:"wet-app-main min-h-screen d-flex flex-column"},[_c('transition',{attrs:{"name":"page","mode":"out-in"}},[_c('router-view',{directives:[{name:"scroll",rawName:"v-scroll",value:(_vm.scroll),expression:"scroll"},{name:"resize",rawName:"v-resize",value:(_vm.resize),expression:"resize"}],staticClass:"flex-grow-1",attrs:{"nextDisabled":_vm.nextDisabled,"nextTitle":_vm.nextTitle,"bottombar":_vm.bottombar},on:{"update:nextDisabled":function($event){_vm.nextDisabled=$event},"update:next-disabled":function($event){_vm.nextDisabled=$event},"update:nextTitle":function($event){_vm.nextTitle=$event},"update:next-title":function($event){_vm.nextTitle=$event}}})],1),(_vm.showImprint)?_c('footer',{staticClass:"text-center my-4 my-sm-5 px-3",class:{
        'wet-imprint-absolute': _vm.imprintAbsolute,
      }},[_c('div',{staticClass:"text-uppercase is-secondary is-size-7"},[_c('a',{staticClass:"empty-ph",attrs:{"id":`${_vm.$route.name}-app-imprint`,"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.$set(_vm.modalsOpened, 'imprint', true)}}},[_vm._v(_vm._s(_vm.strings.commonApp && _vm.strings.commonApp.imprint))])]),_c('div',{staticClass:"is-size-7 empty-ph d-none d-sm-block"},[_vm._v(" "+_vm._s(_vm.strings.commonApp && _vm.strings.commonApp.impressum_description)+" ")])]):_vm._e()],1),(_vm.bottombar)?_c('bottom-bar',{attrs:{"nextDisabled":_vm.nextDisabled,"nextTitle":_vm.nextTitle,"backHide":_vm.backHide,"nextHide":_vm.nextHide},on:{"back":_vm.back,"next":_vm.next},scopedSlots:_vm._u([(_vm.bottomBarPrependMessage
      || ['crop-selection', 'drought'].includes(_vm.$route.name))?{key:"prepend",fn:function(){return [(_vm.bottomBarPrependMessage)?_c('div',{staticClass:"p-0 py-sm-3 mb-4 w-100 is-size-7",domProps:{"innerHTML":_vm._s(_vm.bottomBarPrependMessage)}}):_vm._e(),(['crop-selection', 'drought'].includes(_vm.$route.name))?_c('your-choose',{staticClass:"pb-2 pb-sm-3 w-100"}):_vm._e()]},proxy:true}:null],null,true)},[(_vm.$route.name === 'login')?[(_vm.allowRegistration)?_c('div',{staticClass:"text-center flex-grow-1"},[_vm._v(" "+_vm._s(_vm.strings.login && _vm.strings.login.new_link)+" "),_c('router-link',{staticClass:"text-secondary",attrs:{"to":{ name: 'signup', params: { ..._vm.$route.params }, query: { ...this.$route.query} },"id":"bottombar-signup-link"}},[_vm._v(" "+_vm._s(_vm.strings.login && _vm.strings.login.signup)+" ")])],1):_vm._e(),(!_vm.allowRegistration)?_c('div',{staticClass:"text-center flex-grow-1"}):_vm._e()]:_vm._e(),(_vm.$route.name === 'signup')?[_c('div',{staticClass:"text-center flex-grow-1"},[_vm._v(" "+_vm._s(_vm.strings.commonApp && _vm.strings.commonApp.header_login_text)+" "),_c('router-link',{staticClass:"text-secondary",attrs:{"to":{ name: 'login', params: { ..._vm.$route.params } },"id":"bottombar-login-link"}},[_vm._v(" "+_vm._s(_vm.strings.commonApp && _vm.strings.commonApp.header_login_link_title)+" ")])],1)]:_vm._e()],2):_vm._e(),_c('wet-modal',{attrs:{"id":`${_vm.$route.name}-app-imprint-modal`,"opened":_vm.modalsOpened.imprint,"content":`<div class="wet-note text-center">
      ${_vm.strings.commonApp && _vm.strings.commonApp.imprint_popup}</div>`,"actions":[
      {
        title: _vm.strings.commonApp && _vm.strings.commonApp.ok,
        name: 'ok-imprint',
        event: () => _vm.$set(_vm.modalsOpened, 'imprint', false)
      }
    ]},on:{"close":function($event){return _vm.$set(_vm.modalsOpened, 'imprint', false)}}}),_c('transition-group',{attrs:{"name":"fade-modal"}},_vm._l((_vm.$store.state.modals),function(item){return _c('wet-modal',{key:item.id,attrs:{"id":item.id,"icon":item.icon || 'info',"opened":true,"title":item.title,"content":item.content,"actions":item.actions},on:{"close":() => {
        _vm.$store.commit('DEL_MODAL', item);
        if (typeof item.close === 'function') item.close();
      }}})}),1),_c('transition',{attrs:{"name":"fade-modal"}},[(_vm.intro)?_c('intro-component'):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }