import Cookies from 'js-cookie';

export const arrToSrcset = (arr) => {
  /* return array of wp image sizes to srcset string
  * arr - array
  */
  let result = '';
  if (arr) {
    ['large', 'medium', 'thumb'].forEach((size) => {
      const widthIndex = `${size}-width`;
      result += `${arr[size]} ${arr[widthIndex]}w, `;
    });
  }
  return result;
};

export const getUrlParameter = (parameter) => {
  const searchString = window.location.search;

  if (searchString) {
    const regex = new RegExp(`(?<=[?&]${parameter}=)[\\w]+(?=&)?`);
    const res = regex.exec(searchString);
    return Array.isArray(res) ? res[0] : undefined;
  }
  return undefined;
};

export const derivePartnerCode = () => {
  /* eslint-disable-next-line no-underscore-dangle */
  const p_update = getUrlParameter('partner_code_update');
  const partner_code_update = p_update && p_update === '1';
  const url_partner_code = getUrlParameter('partner_code');
  const cookiePartnerCode = Cookies.get('partner_code');
  const default_partner_code = process.env.VUE_APP_SERVER_VERSION
    ? process.env.VUE_APP_SERVER_VERSION.toLowerCase() : 'wetterheld';

  /**
   * url parameter partner_code_update=1 defined | url parameter partner_code defined
   *   | successful cookie login performed | partner_code cookie defined
   */
  const prio = [
    [true, true, undefined, url_partner_code], /** url parameter partner_code */
    [true, false, undefined, default_partner_code], /** default partner_code */
    [false, undefined, true, cookiePartnerCode], /** partner code cookie */
    [false, true, false, url_partner_code], /** url parameter partner_code */
    [false, false, false, default_partner_code], /** default partner_code */
  ];

  let partnerCode = null;

  for (let i = 0; i < prio.length; i += 1) {
    const prios = prio[i];
    const check = [
      prios[0] === undefined || !!partner_code_update === prios[0],
      prios[1] === undefined || !!url_partner_code === prios[1],
      prios[2] === undefined || !!cookiePartnerCode === prios[2],
    ];
    if (check.every((r) => r === true)) {
      [, , , partnerCode] = prios;
      break;
    }
  }
  return partnerCode;
};
