import Cookies from 'js-cookie';
import { callApi } from '@/api';
import LogService from '@/services/LogService';
import { derivePartnerCode } from '@/common/common';

export default {
  namespaced: true,
  state: {
    loginApi: '/api/user/magic/login?cookie_login=1',
    logoutApi: '/api/user/logout',
    partnerCode: Cookies.get('partner_code'),
  },
  mutations: {
    SET_PARTNER_CODE(state, code) {
      state.partnerCode = code;
      Cookies.remove('partner_code');
      Cookies.set('partner_code', code, { secure: true, sameSite: 'None', expires: 400 });
    },
    DEL_PARTNER_CODE(state) {
      state.partnerCode = null;
      Cookies.remove('partner_code');
    },
  },
  actions: {
    async login({ commit }) {
      const partnerCode = derivePartnerCode();
      LogService.log('partner code module set partner code', partnerCode);
      commit('SET_PARTNER_CODE', partnerCode);
    },
    async set_partner_code({ commit }, partnerCode) {
      LogService.log('partner code module set partner code', partnerCode);
      commit('SET_PARTNER_CODE', partnerCode);
    },
    async logout({ commit, rootState, state }) {
      // logout user
      return callApi(state.logoutApi, { commit, state: rootState })
        .finally(() => {
          // clear from cookie partner code
          const partnerCodeCookie = Cookies.get('partner_code');
          const magicCode = Cookies.get('magic_code');
          if (partnerCodeCookie) {
            commit('DEL_PARTNER_CODE');
          }
          if (magicCode) {
            Cookies.remove('magic_code');
          }
        });
    },
  },
};
