import configEnv from '@/enums/configEnv';
import LogService from '@/services/LogService';

const setLayout = {
  methods: {
    getPartnerDesign(partnerCode, partnerInfo) {
      for (let i = 0; i < partnerInfo.design.length; i += 1) {
        const entry = partnerInfo.design[i];
        LogService.log('entry', entry);
        for (let j = 0; j < entry.partners.length; j += 1) {
          const partner = entry.partners[j];
          LogService.log('partner', partner[0], partnerCode);
          if (partner.codes.includes(partnerCode)) {
            return entry;
          }
        }
      }
      return undefined;
    },
    setCssVariables(
      primary = configEnv.primary_color,
      secondary = configEnv.secondary_color,
      tertiary = configEnv.tertiary_color,
      quaternary = configEnv.quaternary_color,
      quinary = configEnv.quinary_color,
      senary = configEnv.senary_color,
      septenary = configEnv.septenary_color,
      padding_horizontal_input_fields = configEnv.padding_horizontal_input_fields,
      border_radius = configEnv.border_radius,
      border_input_color = configEnv.border_input_color,
    ) {
      const root = document.documentElement;
      if (root) {
        root.style.setProperty('--primary-rgb', this.colorHexToRgb(primary));
        root.style.setProperty('--secondary-rgb', this.colorHexToRgb(secondary));
        root.style.setProperty('--primary', primary);
        root.style.setProperty('--secondary', secondary);
        root.style.setProperty('--tertiary', tertiary);
        root.style.setProperty('--quaternary', quaternary);
        root.style.setProperty('--quinary', quinary);
        root.style.setProperty('--senary', senary);
        root.style.setProperty('--septenary', septenary);
        root.style.setProperty('--padding-horizontal-input-fields', padding_horizontal_input_fields);
        root.style.setProperty('--border-radius', border_radius);
        root.style.setProperty('--border-input-color', border_input_color);
      }
    },
    colorHexToRgb(color) {
      const hexColor = color.replace('#', '');
      const [r, g, b] = [0, 2, 4].map((i) => hexColor.slice(i, i + 2));
      const grbInt = [r, g, b].map((c) => parseInt(c, 16));
      return grbInt.join(', ');
    },
    getVacationBackgroundImage(partnerCode, partnerDesign, vacationStrings) {
      if (partnerDesign) {
        return partnerDesign.background.sizes;
      }
      return vacationStrings.background_image.sizes;
    },
    getVacationPartnerLogo(partnerCode, partnerDesign) {
      if (partnerDesign) {
        return partnerDesign.logo.sizes.medium;
      }
      return '';
    },
    updateVacationString(partnerCode, vacationStrings, partnerDesign) {
      if (partnerDesign) {
        const vStringsNew = vacationStrings;
        vStringsNew.offer_text = partnerDesign.offer_text;
        vStringsNew.section_title = partnerDesign.section_title;
        if (partnerDesign.location) {
          vStringsNew.location_placeholder = partnerDesign.location;
        }
        if (partnerDesign.start_date) {
          vStringsNew.start_date_label = partnerDesign.start_date;
        }
        if (partnerDesign.end_date) {
          vStringsNew.end_date_label = partnerDesign.end_date;
        }

        return vStringsNew;
      }
      return vacationStrings;
    },
  },
};

export default setLayout;
