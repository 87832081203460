import { mapState, mapActions } from 'vuex';

export default {
  computed: {
    ...mapState({
      partnerCode: (state) => state.partnerCodeModule.partnerCode,
    }),
  },
  methods: {
    ...mapActions({
      $logout: 'partnerCodeModule/logout',
    }),
    $login() {
      return this.$store.dispatch('partnerCodeModule/login', this.$route);
    },
  },
};
