<template>
  <div class="btn-group rounded w-100">
    <router-link
      v-for="(item, i) in langs"
      :key="i"
      :to="{
        params: { ...$route.props, lang: item.lang === 'en' ? null : item.lang }
      }"
      :class="{
        'wet-switch-lang-active': lang === item.lang
      }"
      type="button"
      class="btn border"
    ><img
      :alt="item.title"
      :src="item.icon"
      class="mr-2"
      width="18"
      height="12"
    > {{ item.title }}</router-link>
  </div>
</template>
<script>
export default {
  props: {
    langs: {
      type: Array,
      default: () => [],
    },
    lang: {
      type: String,
      default: 'en',
    },
  },
};
</script>
<style lang="scss">
  .wet-switch-lang-active {
    font-weight: bold;
    border-color: var(--primary)!important;
    color: var(--primary);
    background-color: var(--white);
    z-index: 1;
  }
</style>
