import Cookies from 'js-cookie';

function getVStemp() {
  if (Cookies.get('vstemp') === undefined) {
    const vStemp = Date.now().toString(36) + Math.random().toString(36).substring(2);
    Cookies.set('vstemp', vStemp, { secure: true, sameSite: 'None', expires: 400 });
    return vStemp;
  }
  return Cookies.get('vstemp');
}

// eslint-disable-next-line import/prefer-default-export
export { getVStemp };
