export const buyVacation = {
  offer_title_start: '',
  offer_title_start_coupon: '',
  offer_title_end: '',
  offer_subtitle: '',
  period_title: '',
  period_tpl: '',
  location_title: '',
  product_title: '',
  product_tpl: '',
  summary_title: '',
  addr_private_title: '',
  summary_start: '',
  summary_start_full_coupon: '',
  summary_end: '',
  non_insured_days_title: '',
  non_insured_days_text: '',
  agent_title: '',
  agent_plh: '',
  persnl_i_title: '',
  first_name_plh: '',
  error_username: '',
  last_name_plh: '',
  phone_plh: '',
  error_phone: '',
  documents_download: '',
  documents_download_description: '',
  create_acc_title: '',
  email_plh: '',
  error_email_already_used: '',
  error_email: '',
  pass_plh: '',
  error_password: '',
  retype_pass_plh: '',
  error_password_retype: '',
  checkbox_download: '',
  checkbox_download_full_coupon: '',
  error_accept: '',
  accept_chkbx: '',
  accept_chkbx_link: '',
  sepa_info: '',
  next_btn_tpl: '',
  next_btn_tpl_no_price: '',
  known_from: '',
  error_risk_outside: '',
  error_download_link: '',
  error_price_calc: '',
  download_all: '',
  price_title: '',
  next_to_payment: '',
  finilizing_message: '',
  error_registration_wrong_country: '',
  error_registration_wrong_country_short: '',
  error_download: '',
  error_agency: '',
  agency_title: '',
  agency_plh: '',
  agency_text: '',
  agency_title_2: '',
  agency_plh_2: '',
  agency_text_2: '',
  error_agency_2: '',
  continue_in_new_window: '',
  max_payout_text: '',
  error_unselected_insurer: '',
  error_confirm: '',
  confirmation_of_station: '',
  error_confirm_sta: '',
  custom_severity_title_modal: '',
  is: '',
  are: '',
  today: '',
  tomorrow: '',
  urgency_to_buy_text: '',
  until: '',
  rain_amount_header: '',
  rain_amount_info: '',
  next_to_personal_data: '',
  share_quote_text: '',
  share_quote_whatsapp_text: '',
  share_quote_email_text: '',
  share_quote_email_subject: '',
  share_quote_copy_to_clipboard_text: '',
};

export const partnerDesign = {
  design: [
    {
      partners: [{ codes: '-1' }],
      primary: '',
      secondary: '',
      logo: {
        sizes: {
          logos: '',
        },
      },
      background: {
        sizes: {},
      },
      section_title: '',
      offer_text: '',
    },
  ],
};

export const commonApp = {
  ok: '',
  yes: '',
  no: '',
  error_email_already_used: '',
  error_firstname: '',
  error_lastname: '',
  error_phone: '',
  contract_types: [],
  impressum_description: '',
  insurer: '',
  logout_message: '',
  logout: '',
  cancel: '',
  price_including_vat: '',
  weather_type: '',
  less_than_or_equal: '',
  more_than: '',
  unit: [],
  days_dative: '',
  price_including_taxes: '',
  weather_direction: '',
  back_text: '',
};

export const additional = {
  str_and_house_plh: '',
  str_plh: '',
  house_no_plh: '',
  error_registraion_address: '',
  zip_code_plh: '',
  city_plh: '',
  country_plh: '',
  error_username: '',
  first_name_plh: '',
  last_name_plh: '',
  persnl_i_title: '',
  error_phone: '',
  phone_plh: '',
  email_plh: '',
  error_email: '',
  error_house_no: '',
  error_zip: '',
  error_city: '',
};

export const settings = {
  save_settings: '',
  menu_personal: '',
  menu_personal_sub: '',
  menu_company: '',
  menu_company_sub: '',
  menu_bank: '',
  menu_bank_sub: '',
};

export const sections = {
  video_icon: '',
  video_text_drought: '',
  rewiews_title: '',
  rewiews_items: [],
  advantages_title: '',
  organization_logos_title: '',
  info_text: '',
  organization_logos_images: [],
  video_youtube_id_drought: '',
  title_steps: '',
  steps: Array(3).fill({}),
  video_youtube_id_rain: '',
  video_text_rain: '',
  title_vacation: '',
  main_title: '',
  main_title_mobile_drought: '',
  main_title_mobile_rain: '',
  main_description: '',
  description_vacation: '',
  main_images: [
    {
      image: {},
      image_hover: {},
    }, {
      image: {},
      image_hover: {},
    }, {
      image: {},
      image_hover: {},
    },
  ],
};

export const drought = {
  crops: [],
  crops_title: '',
  crops_select_title: '',
  date_from_to: '',
  date_from: '',
  date_to: '',
  insure_btn: '',
};

export const rain = {
  product_title: '',
  product_select_title: '',
  products: {},
  insure_btn: '',
  volksfests: [],
  commercial_product_info: '',
  private_product_link: '',
};

export const vacation = {
  section_title: '',
  section_title_sportvers: '',
  section_title_camping_freunde: '',
  section_title_vispro: '',
  location_label: '',
  location_placeholder: '',
  start_date_label: '',
  end_date_label: '',
  coupon_code_placeholder: '',
  background_image: {
    sizes: [],
  },
  background_image_sportvers: {
    sizes: [],
  },
  background_image_camping_freunde: {
    sizes: [],
  },
  background_image_vispro: {
    sizes: [],
  },
  logo_sportvers: {
    sizes: [],
  },
  logo_camping_freunde: {
    sizes: [],
  },
  logo_vispro: {
    sizes: [],
  },
  error_wrong_country: '',
  error_wrong_country_short: '',
  error_location: '',
  offer_text: '',
  offer_text_sportvers: '',
  offer_text_camping_freunde: '',
  offer_text_vispro: '',
  offer_title_coupon: '',
  error_location_city: '',
  error_coupon_unknown: '',
  error_coupon_already_used: '',
  error_coupon_code_format: '',
  btn_label: '',
  first_date_info: '',
  select_location: '',
};

export const hotel = {
  background_image: {
    sizes: [],
  },
  section_title: '',
  offer_text: '',
  location_label: '',
  location_placeholder: '',
  error_location: '',
  start_date_label: '',
  end_date_label: '',
  btn_label: '',
  time_range_label: '',
  error_wrong_country: '',
  error_wrong_country_short: '',
  error_location_city: '',
};

export const buy = {
  gps_coordinates: '',
  offer: '',
  insurer_name: '',
  business_address: '',
  insured_weather: '',
  period: '',
  deductible_days: '',
  payout_per_day: '',
  maximum_payout: '',
  no: '',
  station_text: '',
  insured_weather_subtitle: '',
  between_hours: '',
  between_hours_adjacent: '',
  error_product_not_active: '',
};

export const insurance = {
  no_contract_title: '',
  no_contract_subtitle: '',
  no_contract_button: '',
  business_type: [],
  claim_payout_btn: '',
  potential_payout: '',
  download_title: '',
  download_subtitle: '',
  error_policy_dwnld: '',
  download_btn: '',
  claim_message: '',
  claim_text: '',
  first_name_plh: '',
  last_name_plh: '',
  additional_first_names_plh: '',
  birth_place_plh: '',
  birth_day_plh: '',
  payout_iban_plh: '',
  error_payout_iban: '',
  error_birth_place: '',
  error_name: '',
  error_send_claim: '',
  insured_location: '',
  starts_in: '',
  data_not_complete: '',
  data_needs_to_be_checked: '',
  claim_per_day_title: '',
  claim_date_title: '',
  claim_value_title: '',
  no_measurement: '',
  no_claim: '',
  not_available: '',
  data_updates_tpl: '',
};

export const passwordReset = {
  title: '',
  title_set_password: '',
  header_title: '',
  header_title_set_password: '',
  button: '',
  button_set_password: '',
  success_status: '',
  error_status: '',
};

export const passwordSet = {
  title: '',
  header_title: '',
  button: '',
  description: '',
  error: '',
  success: '',
};

export const custom = {
  products_title: '',
  products_select_title: '',
  insure_button: '',
  branches: [],
  products: {},
};

export const login = {
  title: '',
  title_alt: '',
  welcome: '',
  welcome_registration: '',
  email: '',
  password: '',
  password_retype: '',
  forget: '',
  or: '',
  skip: '',
  new_link: '',
  signup: '',
  button_login: '',
  button_signup: '',
  error_password_rules: '',
  error_password_identical: '',
  error_login: '',
  error_email: '',
  error_password: '',
  error_login_first: '',
  error_magic_nofound: '',
  error_required: '',
};

export const doneStrings = {
  weather: '',
  period: '',
  day: '',
  days: '',
  location: '',
  low_text: '',
  middle_text: '',
  high_text: '',
  confirmation_of_business_risk: '',
  price_outside_risk_boundaries: '',
  error_price_outside_norain_daily: '',
  error_price_outside_norain_max: '',
  almost_done_subtitle: '',
  severity_item_title: '',
  custom_text_severity: '',
  how_many_rainy_days_subtitle: '',
  singular_day: '',
  plural_days: '',
  select_insurer: '',
  select_insurer_sub: '',
  by_weather_type: '',
  rainy_days_title_tpl: '',
  entity_name: '',
  payout_title: '',
  total_label: '',
  error_days_outside_tpl: '',
  error_payout_outside_tpl: '',
  error_threshold_outside: '',
  alt_price_text: '',
  number_of: '',
  your_payout_text: '',
  severity_description: '',
  historical_payout_template_text: '',
};

export const success = {
  message_title: '',
  message_subtitle: '',
};
