var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"orientation",rawName:"v-orientation",value:(_vm.orientation),expression:"orientation"},{name:"resize",rawName:"v-resize",value:(_vm.resize),expression:"resize"}]},[_c('transition',{attrs:{"name":"page"}},[(!_vm.loadedPage)?_c('div',{staticClass:"preloader",attrs:{"id":"wet-app-preloader"}}):_vm._e()]),_c('router-view',{staticClass:"wet-layout"}),_c('transition',{attrs:{"name":"page"}},[(_vm.$store.state.loading.length > 0)?_c('loadingComponent',{attrs:{"Id":`wet-loading-${_vm.$route.name}`}}):_vm._e()],1),(_vm.development)?_c('wetModal',{attrs:{"id":"wet-dev-api-errors-modal","icon":"error","opened":_vm.devMessage.length > 0,"content":_vm.devMessage.map((m) => `<p>${m}</p>`).join(''),"actions":[
      {
        title: 'CLEAR API ERRORS',
        name: 'clear-dev-api-errors',
        event: () => _vm.$store.commit('DEL_DEV_MESSAGE', [])
      }
    ]},on:{"close":() => _vm.$store.commit('DEL_DEV_MESSAGE', [])}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }