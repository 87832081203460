function toBool(value, def) {
  if (value) {
    return value === 'true';
  }
  return def;
}

const configEnv = {
  own_url: process.env.VUE_APP_OWN_URL ?? 'https://wetterheld.com/home',
  show_prices: toBool(process.env.VUE_APP_SHOW_PRICES, true),
  show_policy: toBool(process.env.VUE_APP_SHOW_POLICY, true),
  logo_file: process.env.VUE_APP_LOGO_FILE ?? 'logo.svg',
  login_success_redirect_page: process.env.VUE_APP_LOGIN_SUCCESS_REDIRECT_PAGE ?? 'main',
  logo_redirect_page: process.env.VUE_APP_LOGO_REDIRECT_PAGE ?? '',
  iban_validation_rule: process.env.VUE_APP_IBAN_VALIDATION_RULE ?? '1',
  primary_color: process.env.VUE_APP_COLORS_1 ?? '#E834B1',
  secondary_color: process.env.VUE_APP_COLORS_2 ?? '#03aaf5',
  tertiary_color: process.env.VUE_APP_COLORS_3 ?? '#000000',
  quaternary_color: process.env.VUE_APP_COLORS_4 ?? '#333333',
  quinary_color: process.env.VUE_APP_COLORS_5 ?? '#dddddd',
  senary_color: process.env.VUE_APP_COLORS_6 ?? '#000000',
  septenary_color: process.env.VUE_APP_COLORS_7 ?? '#aaaaaa',
  border_input_color: process.env.VUE_APP_BORDER_INPUT_COLOR ?? '#D8D8D8',
  header_menu: process.env.VUE_APP_HEADER_MENU ?? '/wp-json/menus/v1/menus/front-page-menu',
  insurance_back_disable: toBool(process.env.VUE_APP_INSURANCE_BACK_DISABLE, false),
  show_imprint: toBool(process.env.VUE_APP_SHOW_IMPRINT, false),
  baloise_strings: toBool(process.env.VUE_APP_BALOISE_STRINGS, false),
  allowed_langs: process.env.VUE_APP_ALLOWED_LANG ?? 'en|de|pl',
  disable_registration: process.env.VUE_APP_DISABLE_REGISTRATION ?? false,
  padding_horizontal_input_fields: process.env.VUE_APP_PADDING_HORIZONTAL_INPUT_FIELDS ?? '1.46rem',
  border_radius: process.env.VUE_APP_BORDER_RADIUS ?? '9999px',
  server_type: process.env.VUE_APP_SERVER_VERSION ?? 'Wetterheld',
};

export default configEnv;
