<template>
  <div  class="wet-section">
    <!-- LOGOUT -->
  </div>
</template>
<script>
import partnerCodeMixin from '@/mixins/partner-code';

export default {
  name: 'LogoutView',
  mixins: [partnerCodeMixin],
  mounted() {
    // logout user
    this.$logout()
      .finally(() => {
        // redirect
        if (this.$route.query.redirect) {
          this.$router.push({
            name: this.$route.query.redirect,
            params: { ...this.$route.params },
          });
        }
      });
  },
};
</script>
