<template>
  <div>
    <appDrawer :drawer.sync="drawerShowed" @close="drawerShowed = false">
      <appDrawerMenu :menu="menu" @click="drawerShowed = false" />
    </appDrawer>
    <headerComponent
      :hide="!!$route.query.iframe"
      :drawer.sync="drawerShowed"
      :phoneBlock="phoneBlock"
      :langs="langs"
      :menu="menu"
      :logo="logo"
      :containered="true"
      showPartner
    />
    <main
      class="wet-app-main"
    >
      <transition name="page" mode="out-in">
        <router-view/>
      </transition>
      <footerComponent
        v-if="!$route.query.iframe"
        @partnermodal="modals.partner.showed = true"
        :data="footer"
        :menu="menu"
      />
    </main>
    <!-- modals section -->
    <modalComponent
      :showed.sync="modals.partner.showed"
      :modtitle="modals.partner.title"
      id="myModal"
    >
      <formComponent />
    </modalComponent>
    <transition name="fade-modal">
      <wetModal
        v-for="(item, i) in $store.state.modals" :key="i"
        :id="item.id"
        :icon="item.icon || 'info'"
        :opened="true"
        @close="() => {
          $store.commit('DEL_MODAL', item);
          if (typeof item.close === 'function') item.close();
        }"
        :title="item.title"
        :content="item.content"
        :actions="item.actions"
      ></wetModal>
    </transition>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
import headerComponent from '@/components/header/headerIndex.vue';
import footerComponent from '@/components/footer/indexFooter.vue';
import appDrawer from '@/components/appDrawer.vue';
import appDrawerMenu from '@/components/drawers/appDrawerMenu.vue';
import formComponent from '@/components/formComponent.vue';
import modalComponent from '@/components/modalComponent.vue';
import common from '@/mixins/common';
import LogService from '@/services/LogService';

export default {
  mixins: [common],
  components: {
    headerComponent,
    footerComponent,
    appDrawer,
    appDrawerMenu,
    formComponent,
    modalComponent,
  },
  data() {
    return {
      drawerShowed: false,
      modals: {
        partner: {
          showed: false,
          title: 'Become a partner',
        },
      },
    };
  },
  computed: {
    ...mapState(['menu', 'langs', 'lang', 'logo', 'wpBaseUrl', 'strings']),
    ...mapGetters(['bgNoScroll']),
    footer() {
      // footer
      LogService.log('LandingLayout', this.strings.sections);
      const langPath = this.lang === 'en' ? '' : `${this.lang}/`;
      const footer = {
        imprint_text: this.strings.sections?.imprint_text,
        copyright_text: this.strings.sections?.copyright_text,
        become_a_partner_text: this.strings.sections?.become_a_partner_text,
        imprint_href: `${process.env.VUE_APP_WP_API_SERVER}/${langPath}privacy-policy/`,
      };
      return footer;
    },
    phoneBlock() {
      // phoneBlock
      return {
        icon: this.strings.sections?.phone_icon,
        phone: this.strings.sections?.phone_number,
        time: this.strings.sections?.working_time,
      };
    },
    copyright_text() {
      return this.strings.sections?.copyright_text;
    },
    language_choose_text() {
      return this.strings.sections?.language_choose_text;
    },
  },
  created() {
    Object.assign(this.$data, this.$options.data.apply(this));
  },
  watch: {
    bgNoScroll: {
      immediate: true,
      handler(bgNoScroll) {
        document.querySelector('body').classList.toggle('overflow-hidden', bgNoScroll);
      },
    },
  },
  destroyed() {
    document.querySelector('body').classList.toggle('overflow-hidden', false);
  },
};
</script>
