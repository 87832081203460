<template>
  <footer class="wet-footer">
    <div class="container">
      <div class="wet-footer-inner">
        <div class="row align-items-center">
          <div class="col-md-6 text-md-left text-center">
            <a :href="logo.href" class="wet-logo">
              <img :src="logo.image" width="180" height="20" alt="logo">
            </a>
          </div>
          <div class="col-md-6">
            <nav class="wet-nav">
              <ul
                id="menu-front-page-menu-footer"
                class="reset flex wet-nav-list justify-content-md-end justify-content-center"
              >
                <li
                  v-for="(item, id) in menu"
                  :key="id"
                  id="menu-item-802"
                  class="menu-item menu-item-home"
                  :class="{
                    'menu-item-has-children': item.submenu,
                  }"
                >
                  <a :href="item.href">{{item.title}}</a>
                  <ul
                    v-if="item.submenu"
                    class="sub-menu"
                  >
                    <li
                      v-for="(subitem, subid) in item.submenu"
                      :key="subid"
                    >
                      <router-link :to="subitem.href">{{subitem.title}}</router-link>
                    </li>
                  </ul>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <div class="wet-footer-info">
        <div class="row wet-footer-info-row">
          <div class="col-md-4 col-sm-12 col-12 text-md-left text-center">
            <div class="wet-footer-info-item">
              <div class="wet-copyright empty-ph">{{data.copyright_text}}</div>
            </div>
          </div>
          <div class="col-md-4 col-sm-4 col-4 text-center">
            <div class="wet-footer-info-item">
              <a
                :href="data.imprint_href"
                class="wet-footer-more empty-ph"
              >{{data.imprint_text}}</a>
            </div>
          </div>
          <div class="col-md-4 col-sm-8 col-8 text-md-right text-center">
            <div class="wet-footer-info-item">
              <a
                class="wet-footer-more empty-ph"
                :href="partner_link"
              >{{data.become_a_partner_text}}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
import { mapState } from 'vuex';

export default {
  props: {
    menu: Array,
    data: Object,
  },
  data() {
    return {
      partner_link: `${process.env.VUE_APP_WP_API_SERVER}/de/wetterheld-partner-werden`,
    };
  },
  computed: {
    ...mapState(['logo']),
  },
};
</script>
<style lang="scss">
  .wet-footer {
    padding: 20px 0;
    font-size: 16px;
    position: relative;
  }
  @media only screen and (max-width: 768px) {
    .wet-footer {
      padding: 15px 0 5px 0;
    }
  }
  .wet-footer-inner,
  .wet-footer-info {
    padding: 25px 0;
  }
  @media only screen and (max-width: 768px) {
    .wet-footer-inner,
    .wet-footer-info {
      padding: 15px 0;
    }
  }
  .wet-footer-inner {
    border-bottom: 1px solid var(--border-input-color);
  }
  @media only screen and (max-width: 768px) {
    .wet-footer-inner .wet-logo {
      margin-bottom: 20px;
    }
  }
  @media only screen and (max-width: 768px) {
    .wet-footer-inner .wet-nav-list li:not(:last-child) {
      margin-right: 0px;
    }
  }
  .wet-footer-more {
    color: var(--primary);
    text-transform: uppercase;
    font-weight: 600;
    width: 100%;
  }
  .wet-footer-more:hover {
    text-decoration: underline;
    color: var(--primary);
  }
  @media only screen and (max-width: 768px) {
    .wet-footer-info-row .col-md-4:nth-child(1) {
      -webkit-box-ordinal-group: 4;
      -webkit-order: 3;
      -ms-flex-order: 3;
      order: 3;
    }
  }
  @media only screen and (max-width: 768px) {
    .wet-footer-info-item {
      margin-bottom: 10px;
    }
  }
  .wet-footer .wet-nav-list {
    position: relative;
  }
  .wet-footer .menu-item-has-children ul.sub-menu {
    position: absolute;
    bottom: 0;
    right: 1rem;
    padding-bottom: 2rem;
  }
</style>
