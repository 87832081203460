<template>
  <div v-orientation="orientation" v-resize="resize">
    <transition name="page">
      <div id="wet-app-preloader" class="preloader" v-if="!loadedPage"></div>
    </transition>
    <router-view class="wet-layout"/>
    <transition name="page">
      <loadingComponent v-if="$store.state.loading.length > 0" :Id="`wet-loading-${$route.name}`"/>
    </transition>
    <wetModal
      v-if="development"
      id="wet-dev-api-errors-modal"
      icon="error"
      :opened="devMessage.length > 0"
      @close="() => $store.commit('DEL_DEV_MESSAGE', [])"
      :content="devMessage.map((m) => `<p>${m}</p>`).join('')"
      :actions="[
        {
          title: 'CLEAR API ERRORS',
          name: 'clear-dev-api-errors',
          event: () => $store.commit('DEL_DEV_MESSAGE', [])
        }
      ]"
    ></wetModal>
  </div>
</template>
<script>
import '@/assets/slick/slick.css';
import '@/assets/slick/slick-theme.css';
import '@/assets/css/style.scss';
import loadingComponent from '@/components/loadingComponent.vue';
import { mapState, mapGetters } from 'vuex';
import seo from '@/mixins/seo';
import LogService from '@/services/LogService';
import configEnv from '@/enums/configEnv';
import { eventNames } from '@/enums/googleTagManager';
import setLayout from '@/mixins/setLayout';
import common from '@/mixins/common';
import wpSection from '@/enums/wpSection';
import { partnerDesign } from '@/enums/stringIndices';
import partnerCode from '@/mixins/partner-code';

export default {
  mixins: [seo, setLayout, common, partnerCode],
  components: {
    loadingComponent,
  },
  data() {
    return {
      str: {
        partnerDesign,
      },
      loadedPage: false,
    };
  },
  beforeCreate() {
    // set moment locale
    this.$moment.locale(this.$store.state.lang);
  },
  created() {
    this.setCssVariables();
    this.getDesign();
    // get menus
    this.$store.dispatch('checkBrowser');
    this.pushDataLayer();
  },
  mounted() {
    this.$login();
    this.loadedPage = true;
    this.resize();
    window.addEventListener('scroll', this.scroll);

    // update view height
    this.setViewH();

    // update breakpoint
    this.$store.dispatch('getBreakpoint');

    //
    document.documentElement.style.setProperty('--bottombar-height', '0px');
  },
  destroyed() {
    window.removeEventListener('scroll', this.scroll);
  },
  computed: {
    ...mapState(['devMessage', 'strings']),
    ...mapGetters(['colorsRgb']),
    development() {
      return process.env.NODE_ENV === 'development';
    },
    dataLayerItem() {
      return {
        event: eventNames.pageView,
        pagePath: this.$route.path,
        pageTitle: this.meta.title,
        originalLocation: `${document.location.protocol}//\
${document.location.hostname}\
${this.$route.fullPath}`,
      };
    },
  },
  watch: {
    $route() {
      /* call the route dependent methods: */
      this.setMeta(this.getLang());
      LogService.log('meta info', this.meta);
      // get menus
      this.getWpMenus();

      // set moment locale
      this.$moment.locale(this.$store.state.lang);

      // push to dataLayer
      this.pushDataLayer();
    },
  },
  methods: {
    getLang() {
      const pattern = `(?<=${configEnv.own_url}/)(${configEnv.allowed_langs})(?=/)`;
      const regex = new RegExp(pattern);
      const res = regex.exec(window.location.href);
      return Array.isArray(res) ? res[0] : 'en';
    },
    async getDesign() {
      await this.getWordPressStrings(
        [wpSection.PARTNER_DESIGN],
        undefined,
        undefined,
        this.getLang(),
      );
      if (this.partnerCode) {
        LogService.log('partnerDesign', this.str.partnerDesign.design[5]);
        LogService.log('partnerCode', this.partnerCode);
        const partnerInfos = this.getPartnerDesign(this.partnerCode, this.str.partnerDesign);
        if (partnerInfos) {
          this.setCssVariables(partnerInfos.primary, partnerInfos.secondary);
          return;
        }
      }
      this.setCssVariables();
    },
    resize() {
      // set globale event when event window.resize
      this.$root.$emit('resize', true);

      // update view height
      this.setViewH();

      // update breakpoint
      this.$store.dispatch('getBreakpoint');

      // update $isMobile
      this.calcIsMobile();
    },
    scroll() {
      // set globale event when event window.scroll
      this.$root.$emit('scroll', true);
    },
    orientation() {
      this.setViewH();

      // update $isMobile
      this.calcIsMobile();
    },
    setViewH() {
      document.documentElement.style.setProperty('--view-height', `${window.innerHeight}px`);
    },
    getWpMenus() {
      // get all wp menus
      this.getWpFrontMenu();
      // this.getWpFooterMenu()
    },
    getWpFrontMenu() {
      // get front page menu
      const { lang } = this.$route.params;
      const url = process.env.VUE_APP_WP_API_SERVER + (lang ? `/${lang}` : '') + configEnv.header_menu;
      LogService.log('getWpFrontMenu', lang, url);
      this.$axios.get(url)
        .then((res) => {
          const menu = res.data.items.map((el) => {
            const submenu = el.child_items
              ? el.child_items.map((sub) => ({
                title: sub.title,
                href: sub.url,
              }))
              : null;
            return {
              title: el.title,
              href: el.url,
              submenu,
            };
          });
          this.$store.commit('SET_MENU', menu);
        })
        .catch(() => {
          LogService.log('API denied or disabled, or check your internet connection!');
        });
    },
    calcIsMobile() {
      const isMobile = Number(window.innerWidth) < 768;
      this.$store.commit('SET_IS_MOBILE', isMobile);
    },
    pushDataLayer() {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push(this.dataLayerItem);
    },
  },
};
</script>
