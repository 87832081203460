<template>
  <div
    class="position-relative"
  >
    <app-drawer v-if="hasDrawer" :drawer.sync="drawer" @close="drawer = false">
      <component :is="drawerComponent" :menu="menu" @click="drawer = false"></component>
    </app-drawer>
    <header-component
      v-bind="headerAttrs"
      v-on="headerEvents"
    >
      <template v-slot:message>
        <transition name="collapse">
          <component
            :is="headerMessageComponent"
            :highlight="['crop-location', 'location'].includes($route.name)"
          ></component>
        </transition>
      </template>
    </header-component>
    <main
      class="wet-app-main min-h-screen d-flex flex-column "
    >
      <transition name="page" mode="out-in">
        <!-- PAGES CONTENT -->
        <router-view
          class="flex-grow-1"
          :nextDisabled.sync="nextDisabled"
          :nextTitle.sync="nextTitle"
          :bottombar="bottombar"
          v-scroll="scroll"
          v-resize="resize"
        />
      </transition>
      <!-- PAGES COMMON FOOTER -->
      <!-- IMPRINT -->
      <footer v-if="showImprint"
        class="text-center my-4 my-sm-5 px-3"
        :class="{
          'wet-imprint-absolute': imprintAbsolute,
        }"
      >
        <div class="text-uppercase is-secondary is-size-7">
          <a
            :id="`${$route.name}-app-imprint`"
            href="#"
            @click.prevent="$set(modalsOpened, 'imprint', true)"
            class="empty-ph"
          >{{strings.commonApp && strings.commonApp.imprint}}</a>
        </div>
        <div class="is-size-7 empty-ph d-none d-sm-block">
          {{strings.commonApp && strings.commonApp.impressum_description}}
        </div>
      </footer>
    </main>
    <!-- BOTTOMBAR -->
    <bottom-bar
      v-if="bottombar"
      @back="back"
      @next="next"
      :nextDisabled="nextDisabled"
      :nextTitle="nextTitle"
      :backHide="backHide"
      :nextHide="nextHide"
    >
      <template v-if="bottomBarPrependMessage
        || ['crop-selection', 'drought'].includes($route.name)" v-slot:prepend>
        <div
          v-if="bottomBarPrependMessage"
          class="p-0 py-sm-3 mb-4 w-100 is-size-7"
          v-html="bottomBarPrependMessage"
        ></div>
        <your-choose class="pb-2 pb-sm-3 w-100"
          v-if="['crop-selection', 'drought'].includes($route.name)" />
      </template>
      <template v-if="$route.name === 'login'">
        <div v-if="allowRegistration" class="text-center flex-grow-1">
          {{ strings.login && strings.login.new_link }}
          <router-link
            :to="{ name: 'signup', params: { ...$route.params }, query: { ...this.$route.query} }"
            id="bottombar-signup-link"
            class="text-secondary"
          >
            {{ strings.login && strings.login.signup }}
          </router-link>
        </div>
        <div v-if="!allowRegistration" class="text-center flex-grow-1"></div>
      </template>
      <template v-if="$route.name === 'signup'">
        <div class="text-center flex-grow-1">
          {{ strings.commonApp && strings.commonApp.header_login_text }}
          <router-link
            :to="{ name: 'login', params: { ...$route.params } }"
            id="bottombar-login-link"
            class="text-secondary"
          >
            {{ strings.commonApp && strings.commonApp.header_login_link_title }}
          </router-link>
        </div>
      </template>
    </bottom-bar>
    <!-- APP LAYOUT MODALS SECTION -->
    <wet-modal
      :id="`${$route.name}-app-imprint-modal`"
      :opened="modalsOpened.imprint"
      @close="$set(modalsOpened, 'imprint', false)"
      :content='`<div class="wet-note text-center">
        ${strings.commonApp && strings.commonApp.imprint_popup}</div>`'
      :actions="[
        {
          title: strings.commonApp && strings.commonApp.ok,
          name: 'ok-imprint',
          event: () => $set(modalsOpened, 'imprint', false)
        }
      ]"
    ></wet-modal>
    <transition-group name="fade-modal">
      <wet-modal
        v-for="item in $store.state.modals" :key="item.id"
        :id="item.id"
        :icon="item.icon || 'info'"
        :opened="true"
        @close="() => {
          $store.commit('DEL_MODAL', item);
          if (typeof item.close === 'function') item.close();
        }"
        :title="item.title"
        :content="item.content"
        :actions="item.actions"
      ></wet-modal>
    </transition-group>
    <transition name="fade-modal">
      <intro-component v-if="intro" />
    </transition>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import common from '@/mixins/common';
import done from '@/mixins/done';
import headerComponent from '@/components/header/headerIndex.vue';
import bottomBar from '@/components/footer/BottomBar.vue';
import appDrawer from '@/components/appDrawer.vue';
import appDrawerMenu from '@/components/drawers/appDrawerMenu.vue';
import settingsDrawer from '@/components/drawers/settingsDrawer.vue';
import yourChoose from '@/components/crop-selection/yourChoose.vue';
import headerMessageLocation from '@/components/header/messages/headerMessageLocation.vue';
import introComponent from '@/components/introComponent.vue';
import configEnv from '@/enums/configEnv';

export default {
  mixins: [common, done],
  components: {
    headerComponent,
    bottomBar,
    appDrawer,
    appDrawerMenu,
    settingsDrawer,
    yourChoose,
    headerMessageLocation,
    introComponent,
  },
  data() {
    return {
      modalsOpened: {
        imprint: false,
      },
      allowRegistration: !configEnv.disable_registration,
      showImprint: configEnv.show_imprint,
      wpCommonAppApi: '/wp-json/acf/v3/options/common-app',
      nextDisabled: true,
      nextTitle: '',
      backLink: '',
      nextLink: '',
      totalInHeader: true,
      drawer: false,
      drawerAsAside: false, /** if true always show drawer on desktop */
    };
  },
  computed: {
    ...mapState(['strings', 'bottomBarPrependMessage', 'order', 'lang', 'menu', 'logo', 'intro']),
    ...mapGetters(['bgNoScroll']),
    headerAttrs() {
      const phoneBlock = {
        icon: this.strings.commonApp?.phone_icon?.url,
        phone: this.strings.commonApp?.phone_number,
        time: this.strings.commonApp?.working_time,
      };
      return {
        drawer: this.drawer,
        phoneBlock,
        langs: this.langs,
        menu: this.menu,
        logo: this.logo,
      };
    },
    headerEvents() {
      return {
        'update:drawer': () => {
          this.drawer = !this.drawer;
        },
      };
    },
    bottombar() {
      return !['404', 'password-reset', 'forgot'].includes(this.$route.name);
    },
    nextHide() {
      // next button hide rules
      return ['insurance', 'insurance-id', 'finalize-vacation'].includes(this.$route.name);
    },
    backHide() {
      return this.$route.name === 'success' || (['insurance', 'settings'].includes(this.$route.name)
        && configEnv.insurance_back_disable);
    },
    imprintAbsolute() {
      return this.$route.name === 'crop-location';
    },
    hasDrawer() {
      return true;
    },
    drawerComponent() {
      const drawer = 'appDrawerMenu';
      return drawer;
    },
    headerMessageComponent() {
      let component = '';
      if (['crop-location'].includes(this.$route.name)) {
        component = 'headerMessageLocation';
      }
      return component;
    },
  },
  watch: {
    bgNoScroll: {
      immediate: true,
      handler(bgNoScroll) {
        document.querySelector('body').classList.toggle('overflow-hidden', bgNoScroll);
      },
    },
  },
  mounted() {
    // set small text class for page
    document.documentElement.classList.toggle('small-interface', true);

    //
    this.setDrawer();
  },
  destroyed() {
    // remove small text class for page
    document.documentElement.classList.toggle('small-interface', false);

    document.querySelector('body').classList.toggle('overflow-hidden', false);
  },
  methods: {
    scroll() {
      const scrollPercent = document.documentElement.scrollTop
        / (document.documentElement.scrollHeight - document.documentElement.clientHeight);
      if (scrollPercent > 0.75) {
        this.totalInHeader = false;
      } else this.totalInHeader = true;
    },
    resize() {
      this.setDrawer();
    },
    setDrawer() {
      const w = window.innerWidth;
      if (w > 768) {
        this.drawer = this.drawerAsAside;
      } else this.drawer = false;
    },
    back() {
      this.$root.$emit('buttonBack', true);
    },
    next() {
      this.$root.$emit('buttonNext', true);
    },
  },
};
</script>
<style lang="scss">
  .wet-imprint-absolute {
    position: absolute;
    bottom: var(--bottombar-height);
    left: 0;
    width: 100%;
    background-color: rgba($color: white, $alpha: .5);
    margin: 0!important;
    padding: 1rem;
    backdrop-filter: blur(5px);
  }
</style>
