<template>
  <div>
    <!-- CreateNewVacationContract -->
  </div>
</template>

<script>
import common from '@/mixins/common';
import partnerCode from '@/mixins/partner-code';
import partnerCodeEnum from '@/enums/partnerCode';
import LogService from '@/services/LogService';
import { getVStemp } from '@/common/vStemp';

export default {
  mixins: [common, partnerCode],
  data() {
    return {
      vstemp: getVStemp(),
      query_params: [
        'partner_code',
        'hotel_name',
        'hotel_street',
        'hotel_zip',
        'hotel_town',
        'hotel_country',
        'hotel_phone',
        'hotel_email',
        'user_first_name',
        'user_last_name',
        'user_phone',
        'start_date',
        'end_date',
        'daily_payout',
        'cities_allowed',
        'create_session',
        'source',
      ],

      partner_code: undefined,
      hotel_name: undefined,
      hotel_street: undefined,
      hotel_zip: undefined,
      hotel_town: undefined,
      hotel_country: undefined,
      hotel_phone: undefined,
      hotel_email: undefined,
      user_first_name: undefined,
      user_last_name: undefined,
      user_phone: undefined,
      start_date: undefined,
      end_date: undefined,
      daily_payout: undefined,
      agency_id: undefined,
      show_agency_id: undefined,
      cities_allowed: undefined,
      create_session: undefined,
      source: undefined,

      str: {
        CreateNewVacationContract: {
          title: 'testName',
        },
      },
    };
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      this.addQueryParams();
      if (this.partner_code) {
        await this.$login();
      }
      LogService.log('create_session', this.create_session);
      const result = await this.$webAppAPI.postHotelCreateSession(
        this.partner_code,
        this.hotel_name,
        this.hotel_street,
        this.hotel_zip,
        this.hotel_town,
        this.hotel_country,
        this.start_date,
        this.end_date,
        this.daily_payout,
        this.user_first_name,
        this.user_last_name,
        this.user_phone,
        this.agency_id,
        this.show_agency_id,
        this.cities_allowed,
        this.create_session,
        this.source,
        this.vstemp,
      );
      const destination = result.data.site;
      LogService.log('createNewVacationContract, destination', destination);
      const router = { name: destination, params: { ...this.$route.params } };

      if (result.data.url_params) {
        router.query = result.data.url_params;
      }

      if (destination === 'vacation') {
        router.query = { ...router.query, ...this.deriveQueryVacationLanding() };
      }
      this.$router.push(router);
    },
    addQueryParams() {
      this.query_params.forEach((index) => {
        if (this.$route.query[index]) {
          if (!['create_session', 'cities_allowed'].includes(index)) {
            this[index] = this.$route.query[index];
          } else {
            this[index] = this.$route.query[index] === 'true';
          }

          LogService.log(`query param ${index} found`, this[index]);
        }
      });
      LogService.log('addQueryParams, route query', this.$route.query);
      LogService.log('addQueryParams, route partner_code', this.$route.query.partner_code);

      if (this.$route.query.agency_id !== undefined
        && this.$route.query.partner_code === partnerCodeEnum.tui) {
        this.agency_id = this.$route.query.agency_id;
      } else if (this.$route.query.show_agency_id
        && this.$route.query.show_agency_id.toString() === '1'
        && this.$route.query.partner_code === partnerCodeEnum.tui) {
        this.show_agency_id = true;
      }
    },
    deriveQueryVacationLanding() {
      LogService.log('createNewVacationContract, partner_code',
        this.partner_code, partnerCodeEnum.tui, this.partner_code === partnerCodeEnum.tui);
      LogService.log('createNewVacationContract, show_agency_id', this.show_agency_id);
      LogService.log('createNewVacationContract, agency_id', this.agency_id);

      if (this.partner_code === partnerCodeEnum.tui && this.agency_id !== undefined) {
        return { agency_id: this.agency_id, partner_code: this.partner_code };
      }

      if (this.partner_code === partnerCodeEnum.tui && this.show_agency_id === true) {
        return { show_agency_id: 1, partner_code: this.partner_code };
      }
      return {};
    },
  },
};
</script>

<style scoped>

</style>
