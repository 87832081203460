export const productNames = {
  vacation: 'vacation insurance',
};

export const eventNames = {
  pageView: 'Pageview',
  purchase: 'purchase',
  viewItem: 'view_item',
  thresholdRollout: 'threshold_rollout',
  documentsDownloaded: 'documents_downloaded',
  openedPersonalData: 'opened_personal_data',
  quoteLinkCopied: 'quote_link_copied',
};

export const currencyAbbreviation = {
  euro: 'EUR',
};
