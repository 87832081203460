<template>
  <div>
    <div v-resize="resize" ref="bottombar" class="navbar wet-bottombar fixed-bottom flex-column">
      <div v-if="$slots.prepend" class="container">
        <slot name="prepend"></slot>
      </div>
      <div class="container d-flex">
        <slot>
          <button
            v-if="!backHide"
            id="wet-button-back"
            class="wet-button wet-button-flat empty-ph"
            :class="{
              'flex-fill': nextHide,
            }"
            style="min-width:4rem;"
            @click.prevent="$emit('back')"
          >{{strings.commonApp && strings.commonApp.back_text}}</button>
          <button
            v-if="!nextHide"
            id="wet-button-next"
            class="wet-button ml-3 flex-fill empty-ph"
            :class="{
              'loading': loading.length > 0
            }"
            style="min-width:10rem;"
            @click.prevent="$emit('next')"
            :disabled="nextDisabled || loading.length > 0"
          >{{ next }}</button>
        </slot>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';

export default {
  props: {
    nextDisabled: Boolean,
    nextTitle: String,
    backHide: Boolean,
    nextHide: Boolean,
  },
  computed: {
    ...mapState(['strings', 'loading']),
    next() {
      let next = this.nextTitle || this.strings.commonApp?.next_text;
      if (this.$route.name === 'buy') next = this.strings.buy?.buy_now;
      return next;
    },
  },
  mounted() {
    this.resize();
  },
  updated() {
    this.resize();
  },
  destroyed() {
    document.documentElement.style.setProperty('--bottombar-height', '0px');
  },
  methods: {
    resize() {
      // calc height bottombar && set into css var
      const { bottombar } = this.$refs;
      if (bottombar) {
        document.documentElement.style.setProperty('--bottombar-height', `${bottombar.offsetHeight}px`);
      }
    },
  },
};
</script>
<style lang="scss">
  .wet-bottombar {
    backdrop-filter: blur(5px);
    background-color: rgba(255,255,255,.9);
    box-shadow: 0 0 1rem rgba($color: gray, $alpha: .3);
    min-height: 3.5rem;
  }
  .wet-bottombar-wrapper {
    background-color: transparent;
  }
</style>
