<template>
  <div class="">
    <ul class="list-unstyled mb-0 p-3 border-bottom">
      <li
        class=""
        v-for="(item, index) in menu"
        :key="index"
      >
        <a
          v-if="item.href.includes('http')"
          :href="item.href"
          :class="{
            'wet-append-chevron-right': item.submenu,
          }"
          class="py-2 w-100"
        >{{item.title}}</a>
        <router-link
          v-else
          :to="['/app'].includes(item.href) ? '/home' : item.href"
          :class="{
            'wet-append-chevron-right': item.submenu,
          }"
          class="py-2 w-100"
          event=""
          @click.native="menuClick(index)"
        >{{item.title}}</router-link>
        <ul
          v-if="item.submenu"
          class="list-unstyled"
          v-collapse="openedSubmenu[index]"
        >
          <li
            v-for="(subitem, subid) in item.submenu"
            :key="subid"
          >
            <router-link
              :to="subitem.href"
              class="pl-3 py-2 w-100"
              @click.native="$emit('click')"
            >{{subitem.title}}</router-link>
          </li>
        </ul>
      </li>
    </ul>
    <div class="p-3">
      <div class="py-2">{{strings.commonApp && strings.commonApp.switch_lang}}</div>
      <switch-lang
        :langs="langs"
        :lang="lang"
      />
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import switchLang from '@/components/switchLang.vue';
import LogService from '@/services/LogService';

export default {
  props: {
    menu: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    switchLang,
  },
  data() {
    return {
      openedSubmenu: [],
    };
  },
  computed: {
    ...mapState(['strings', 'langs', 'lang']),
    currentLang() {
      // compute current lang by url
      const current = this.$route.params.lang ? this.$route.params.lang.toLowerCase() : 'en';
      LogService.log('currentLang', current, this.langs);
      return this.langs.filter((el) => el.lang === current)[0];
      // return this.$_.filter(this.langs, { 'lang': current})[0]
    },
    langsFiltered() {
      // filter other langs except current
      const current = this.$route.params.lang ? this.$route.params.lang.toLowerCase() : 'en';
      return this.langs.filter((el) => el.lang !== current);
      // return this.$_.reject(this.langs, { 'lang': current})
    },
  },
  methods: {
    submenuToggle(i) {
      this.$set(this.openedSubmenu, i, !this.openedSubmenu[i]);
    },
    menuClick(i) {
      if (this.menu[i].submenu) {
        this.submenuToggle(i);
      } else {
        window.location = this.menu[i].href;
      }
    },
  },
};
</script>
