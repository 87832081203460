<template>
  <picture>
    <template v-if="!size && image && image.sizes">
      <source
        v-for="(size, i) in sizes"
        :key="i"
        :srcset="image.sizes[i]"
        :media="`(max-width: ${size}px)`"
        @load="(e) => $emit('load', e)"
      >
    </template>
    <img
      :src="src || '#'" alt="" width="100%"
      :height="`${100*ratio}%`"
      @load="(e) => $emit('load', e)"
    >
  </picture>
</template>
<script>
export default {
  props: {
    image: {
      type: Object,
      default: () => {},
    },
    size: String,
    ratio: {
      type: Number,
      default: 1,
    },
    sizes: {
      type: Object,
      default: () => ({
        thumb: '768',
        medium: '992',
        large: '1200',
      }),
    },
  },
  computed: {
    src() {
      return !this.size ? this.image?.url : this.image?.sizes?.[this.size];
    },
  },
};
</script>
