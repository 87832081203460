import { mapState } from 'vuex';
import { numToPriceFormat } from '@/common/snippets';

const {
  availableWeatherDirs,
  availableWeatherTypes,
  availableProductPeriod,
} = require('@/constants');

const done = {
  data() {
    // ONLY CONSTANTS!!!
    return {
      availableWeatherDirs,
      availableWeatherTypes,
      availableProductPeriod,
      errorStrings: {
        invalidUser: 'Invalid user',
        outsideRisk: 'No contract in risk boundaries found',
      },
    };
  },
  computed: {
    ...mapState(['menu', 'langs', 'lang', 'logo', 'wpBaseUrl', 'appLayout', 'strings']),
  },
  methods: {
    toCent: (numb) => Math.floor(numb * 100) / 100,
    getApiDataFromOptimized(apiData, optimized) {
      const newApiData = { ...apiData };
      // change api_data presettings if optimized data was
      newApiData.days = this.isMaximalMode
        ? newApiData.contract_days - optimized.risk_days : optimized.risk_days;
      newApiData.severity = optimized.severity;
      newApiData.threshold = optimized.threshold;
      newApiData.risk_days = optimized.risk_days;

      // set max_payout/daily_payout
      if (this.isMaximalMode) {
        newApiData.max_payout = Math.round(optimized.bundle_maximal_payout);
        newApiData.daily_payout = this.getDailyPayout(newApiData.max_payout, newApiData.days);
      } else {
        const insuredDays = newApiData.contract_days - optimized.risk_days;
        const optimizedDailyPayout = this.getDailyPayoutBounded(
          this.maxContractPayout,
          insuredDays,
          Math.min(this.maxDailyPayout, newApiData.daily_payout),
        );
        newApiData.daily_payout = optimizedDailyPayout;
        newApiData.max_payout = this.toCent(newApiData.daily_payout * insuredDays);
      }

      newApiData.payout_days_array = optimized.payout_days_array;
      return newApiData;
    },
    mapGetOrderData(res) {
      let orderData = {};

      if (process.env.VUE_APP_DROUGHT_PAGE_VERSION === 'order-bundle') {
        orderData = {
          max_payout: res.data.order_bundle[0].reduction,
          daily_payout: res.data.order_bundle[0].daily_payout,
          days: res.data.order_bundle[0].weather?.direction !== this.availableWeatherDirs.norain
            ? res.data.order_bundle[0].risk_days
            : res.data.order_bundle[0].contract_days - res.data.order_bundle[0].risk_days,
          risk_days: res.data.order_bundle[0].risk_days,
          contract_days: res.data.order_bundle[0].contract_days,
          weather: res.data.order_bundle[0].weather?.type,
          weather_direction: res.data.order_bundle[0].weather?.direction,
          severity: res.data.order_bundle[0].weather?.severity,
          business_type: res.data.order_bundle[0].business?.type,
          location: {
            station: res.data.order_bundle[0].station?.name,
            address: res.data.order_bundle[0].location?.address,
            distance: res.data.order_bundle[0].station?.distance,
            id: res.data.order_bundle[0].station?.id,
            lat: res.data.order_bundle[0].station?.latitude,
            lon: res.data.order_bundle[0].station?.longitude,
          },
          maximal_value_daily_payout: res.data.settings.order.maximal_value_daily_payout,
          // eslint-disable-next-line max-len
          maximal_value_max_contract_payout: res.data.settings.order.maximal_value_max_contract_payout,
          threshold: Number(res.data.order_bundle[0].weather?.threshold),
          dates: res.data.order?.dates,
          branchId: res.data.order?.business?.branch_id,
          min_risk_days_ratio: res.data.settings.order.min_risk_days_ratio,
          max_risk_days_ratio: res.data.settings.order.max_risk_days_ratio,
          crop: res.data.order?.business?.subtype,
          hours: res.data.order?.hours,
          currency: res.data.order?.currency,
          payout_input: res.data.settings.business.payout_input,
          minThreshold: res.data.settings.business.min_threshold,
          maxThreshold: res.data.settings.business.max_threshold,
          insurers: res.data.insurers,
        };
      } else {
        orderData = {
          max_payout: res.data.order.reduction,
          daily_payout: res.data.order.daily_payout,
          days: res.data.order.weather?.direction !== this.availableWeatherDirs.norain
            ? res.data.order.risk_days : res.data.order.contract_days - res.data.order.risk_days,
          risk_days: res.data.order.risk_days,
          contract_days: res.data.order.contract_days,
          weather: res.data.order.weather?.type,
          weather_direction: res.data.order.weather?.direction,
          severity: res.data.order.weather?.severity,
          business_type: res.data.order.business?.type,
          location: {
            station: res.data.order.station?.name,
            address: res.data.order.location?.address,
            distance: res.data.order.station?.distance,
            id: res.data.order.station?.id,
            lat: res.data.order.station?.latitude,
            lon: res.data.order.station?.longitude,
          },
          maximal_value_daily_payout: res.data.settings.order.maximal_value_daily_payout,
          // eslint-disable-next-line max-len
          maximal_value_max_contract_payout: res.data.settings.order.maximal_value_max_contract_payout,
          threshold: Number(res.data.order.weather?.threshold),
          dates: res.data.order?.dates,
          branchId: res.data.order?.business?.branch_id,
          min_risk_days_ratio: res.data.settings.order.min_risk_days_ratio,
          max_risk_days_ratio: res.data.settings.order.max_risk_days_ratio,
          crop: res.data.order?.business?.subtype,
          hours: res.data.order?.hours,
          currency: res.data.order?.currency,
          payout_input: res.data.settings.business.payout_input,
          minThreshold: res.data.settings.business.min_threshold,
          maxThreshold: res.data.settings.business.max_threshold,
          insurers: res.data.insurers,
        };
      }

      return orderData;
    },
    getDailyPayout(maximalPayout, insuredDays) {
      return this.toCent(maximalPayout / insuredDays);
    },
    getDailyPayoutBounded(maximalPayout, insuredDays, maxDailyPayout) {
      return Math.min(
        this.getDailyPayout(maximalPayout, insuredDays),
        maxDailyPayout,
      );
    },
    getMaximalPayoutBounded(dailyPayout, insuredDays, maximalContractPayout) {
      return Math.min(this.toCent(dailyPayout * insuredDays), maximalContractPayout);
    },
    numToPriceFormat,
  },
};

export default done;
