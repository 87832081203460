<template>
  <div>
    <router-view :beforeOrder="login" />
    <reviewsComponent :data="reviewsSection" />
    <template v-if="!$route.query.iframe">
      <stepsComponent :main-title="stepsSection.title" :steps="stepsSection.steps"/>
      <div class="wet-section">
        <div class="container">
          <div class="wet-section-title text-center empty-ph" v-html="organizationsSection.title">
          </div>
        </div>
      </div>
      <div class="wet-section wet-logos-slider wet-organizations" >
        <logosSlider :items="organizationsSection.items" name="organizations"/>
      </div>
      <div v-html="stringsSections.prices_title" class="wet-section-title text-center"/>
      <div class="wet-section wet-logos-slider wet-organizations" >
        <logosSlider
          :items="stringsSections.prices_images"
          image-index-name="prices_images_image"
          link-index-name="prices_images_link"
          name="organizations"
          class="mb-4"
        />
      </div>
      <div class="wet-section wet-info">
        <div class="container">
          <div class="wet-section-title text-center empty-ph" v-html="advText"></div>
        </div>
      </div>
      <div class="wet-section wet-reinsured">
        <div class="container">
          <div class="wet-section-title text-center empty-ph" v-html="infoText"></div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import common from '@/mixins/common';

export default {
  mixins: [common],
  components: {
    stepsComponent: () => import(/* webpackChunkName: "stepsComponent" */ '@/components/sections/steps/stepsIndex.vue'),
    reviewsComponent: () => import(/* webpackChunkName: "reviewsComponent" */ '@/components/sections/reviews/reviewsIndex.vue'),
    logosSlider: () => import(/* webpackChunkName: "logosSlider" */ '@/components/logosSlider.vue'),
  },
  data() {
    return {
      loginApi: '/api/user/magic/login?cookie_login=1',
    };
  },
  computed: {
    ...mapState(['strings', 'lang']),
    stringsSections() {
      return this.strings.sections || {
        prices_images: [],
        prices_title: '',
        video_youtube_id_drought: '',
        title_steps: '',
        steps: [
          {
            images: {},
          },
          {
            images: {},
          },
          {
            images: {},
          },
        ],
        rewiews_title: '',
        rewiews_items: [],
        advantages_title: '',
        info_text: '',
        organization_logos_title: '',
        organization_logos_images: [
          {
            organization_logos_link: '',
            organization_logos_image: {},
          },
        ],
      };
    },
    droughtVideo() {
      return {
        youtube: this.stringsSections.video_youtube_id_drought,
      };
    },
    stepsSection() {
      // set data for stepsSection
      return {
        title: this.stringsSections.title_steps,
        steps: this.stringsSections.steps,
      };
    },
    reviewsSection() {
      // set data for reviewsSection
      return {
        title: this.stringsSections.rewiews_title,
        reviews: this.stringsSections.rewiews_items,
      };
    },
    advText() {
      return this.stringsSections.advantages_title;
    },
    infoText() {
      return this.stringsSections.info_text;
    },
    organizationsSection() {
      // organizationsSection
      return {
        title: this.stringsSections.organization_logos_title,
        items: (this.stringsSections.organization_logos_images).map((el) => ({
          link: el.organization_logos_link,
          image: el.organization_logos_image,
        })),
      };
    },
  },
  methods: {
    async login() {
      const magicCode = this.$Cookies.get('magic_code');
      if (!magicCode) return;
      const headers = {
        'Magic-Code': magicCode,
      };
      await this.callApi({ url: this.loginApi, headers }).catch(() => {});
    },
  },
};
</script>
<style lang="scss">
</style>
