import axios from 'axios';
import { nanoid } from 'nanoid';
import LogService from '@/services/LogService';

const axiosInstance = axios.create();
const isDev = process.env.NODE_ENV === 'development';

const checkForShowError = (url, status, data) => {
  let show = true;
  if (
    (url.includes('api/order/price') && status?.toString() === '400')
    || (url.includes('api/user/register') && ['403', '422'].includes(status?.toString()))
    || (url.includes('api/user/login') && ['401'].includes(status?.toString()))
    || (url.includes('api/user/order') && ['400'].includes(status?.toString()))
    || (url.includes('api/user/info') && ['400'].includes(status?.toString()))
    || (url.includes('api/user/status') && ['400'].includes(status?.toString()))
    || (url.includes('api/user/download/quote') && ['404'].includes(status?.toString()))
    || (url.includes('api/user/download/policy') && ['500'].includes(status?.toString()))
    || (url.includes('api/user/data/update') && ['422'].includes(status?.toString()))
    || (url.includes('api/user/magic/order') && ['404', '400'].includes(status?.toString()))
    || (url.includes('api/user/magic/login') && ['404', '400'].includes(status?.toString()))
    || (/api\/user\/order\/.+/.test(url) && ['404'].includes(status?.toString()))
    || (url.includes('api/user/reset_password') && ['422'].includes(status?.toString()))
    || (url.includes('/api/user/send_reset_email') && ['422'].includes(status?.toString()))
    || (url.includes('/api/station/find') && ['422'].includes(status?.toString())
      && data?.errors?.['location.country_code'] !== undefined)
  ) {
    show = false;
  }
  return show;
};

/**
 * call api
 * update token
 * options = { url, method, data, params, headers, loading } || url<String>
 * @param {String|Object} o
 * @param {String} o.url
 * @param {String} o.method
 * @param {Object} o.data
 * @param {Object} o.params
 * @param {Object} o.headers
 * @param {Boolean} o.loading - is loading conf. if false
 * - pass showing a loading component, default is true
 * @param {Object} store - store instance
 */
const callApi = (o, store) => {
  const options = typeof o === 'string' ? { url: o } : o;
  const method = options.method || 'get';
  let showLoading = true;
  if (options.loading !== undefined) showLoading = !!options.loading;
  const id = nanoid();
  if (showLoading) store.commit('SET_LOADING', id);

  const headers = {
    ...options.headers,
    Authorization: process.env.VUE_APP_API_KEY,
    lang: store.state.lang,
  };

  if (store.state.token) headers.token = store.state.token;

  const url = process.env.VUE_APP_API_SERVER + options.url;

  // return promise
  return axiosInstance({
    ...options,
    method,
    url,
    headers,
  })
    .then((res) => {
      store.commit('SET_TOKEN', res.headers.token, { root: true });
      return Promise.resolve(res);
    })
    .catch((err) => {
      LogService.log('callAPI old service, error caught');
      const token = err.response?.headers?.token || null;
      if (token) store.commit('SET_TOKEN', token, { root: true });

      if (isDev && checkForShowError(url, err.response.status, err.response?.data)) {
        // show error message
        store.commit('ADD_DEV_MESSAGE', `METHOD ${method} URL of API ${url} return status code ${err.response.status}`, { root: true });
      }
      return Promise.reject(err);
    })
    .finally(() => {
      if (showLoading) store.commit('DEL_LOADING', id);
    });
};

export {
  axiosInstance,
  callApi,
};
