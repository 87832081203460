<template>
  <div
    class="wet-header-message py-2 py-sm-3 d-flex
      justify-content-between align-items-baseline font-weight-bold"
  >
    <div>{{ strings.buy && strings.buy.summary }}</div>
    <div class="text-right">
      {{ strings.commonApp && strings.commonApp.price_including_taxes}}:
      <span class="is-size-6 is-size-md-4">{{
        numToPriceFormat(order.price, lang, true)[0]
      }}</span>{{numToPriceFormat(order.price, lang, true)[1]}}{{
        numToPriceFormat(order.price, lang, true)[2]}} {{ currency }}
    </div>
  </div>
</template>
<script>
import { numToPriceFormat } from '@/common/snippets';
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState(['order', 'lang', 'strings']),
    currency() {
      return this.order.currency;
    },
  },
  methods: {
    numToPriceFormat,
  },
};
</script>
