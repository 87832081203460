export default class ValidationItem {
  constructor(data = {}) {
    this.min_contract_start_postponement_less_29 = data.min_contract_start_postponement_less_29;
    this.min_contract_start_postponement = data.min_contract_start_postponement;
    this.max_contract_start_postponement = data.max_contract_start_postponement;
    this.min_contract_length = data.min_contract_length;
    this.max_date_range = data.max_date_range;
    this.dates_adjacent = data.dates_adjacent;
    this.min_hours = data.min_hours;
    this.max_hours = data.max_hours;
    this.default_start_hour = data.default_start_hour;
    this.default_end_hour = data.default_end_hour;
    this.min_area = data.min_area;
    this.max_area = data.max_area;
    this.min_crop_value_per_hectar = data.min_crop_value_per_hectar;
    this.max_crop_value_per_hectar = data.max_crop_value_per_hectar;
    this.default_start_date = data.default_start_date;
    this.default_end_date = data.default_end_date;
    this.insurance_length = data.insurance_length;
    this.time_settings = data.time_settings;
  }
}
