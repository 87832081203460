<template>
  <aside v-resize="resize">
    <div
      class="wet-drawer-overlay"
      :class="{
        'active': drawer,
        'mobile': mobile,
      }"
      @click="$emit('close')"
    ></div>
    <div
      ref="drawer"
      class="wet-drawer"
      :class="{
        'active': drawer,
        'mobile': mobile,
      }"
    >
      <div class="wet-drawer-inner">
        <div class="wet-drawer-content">
          <slot></slot>
        </div>
        <div class="p-3 d-md-none">
          © Wetterheld 2020
        </div>
      </div>
    </div>
  </aside>
</template>
<script>
export default {
  props: {
    drawer: Boolean,
  },
  data() {
    return {
      mobile: false,
    };
  },
  mounted() {
    this.resize();
  },
  updated() {
    this.resize();
  },
  destroyed() {
    document.documentElement.style.setProperty('--left-drawer-w', '0px');
  },
  methods: {
    resize() {
      // calc height bottombar && set into css var
      const { drawer } = this.$refs;
      if (drawer) {
        const w = this.drawer && !this.mobile ? drawer.offsetWidth : 0;
        document.documentElement.style.setProperty('--left-drawer-w', `${w}px`);
      }

      // calc mobile version
      const ww = window.innerWidth;
      if (ww > 768) {
        this.mobile = false;
      } else this.mobile = true;
    },
  },
};
</script>
<style lang="scss">
  .wet-drawer {
    position: fixed;
    width: 100%;
    max-width: 320px;
    top: var(--header-height);
    height: calc(var(--view-height) - var(--header-height) - var(--bottombar-height));
    background-color: #fff;
    transform: translateX(-100%);
    transition: .3s cubic-bezier(.4,0,.2,1);
    bottom: var(--bottombar-height);
    border-right: 1px solid var(--gray-light);

    &.mobile {
      z-index: 1032;
      padding-top: 0;
      padding-bottom: 0;
      border-right: none;
      top: 0;
      height: var(--view-height);
    }

    &.active {
      transform: translateX(0);
    }
  }
  .wet-drawer-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(33, 83, 204, 0.8);
    left: 0;
    top: 0;
    visibility: hidden;
    opacity: 0;
    transition: all 0.1s ease-in;
    display: none;

    &.mobile {
      z-index: 1031;
      display: block;
    }

    &.active {
      visibility: visible;
      opacity: 1;
    }
  }
  .wet-drawer-inner {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .wet-drawer-content {
    flex: 1;
    overflow-y: auto;
  }
</style>
