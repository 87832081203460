<template>
  <div>
    <div class="empty-ph font-weight-bold text-center text-secondary">
      {{cropSelection.you_choose}}
    </div>
    <p class="empty-ph text-center">
      {{selected.join(', ')}}
    </p>
  </div>
</template>
<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState(['settings', 'strings', 'cropSelected']),
    selected() {
      return Object.entries(this.cropSelected || {}).map(([id, crop]) => `${crop.area} ${this.ha} ${this.crops[id]}`);
    },
    crops() {
      return Object.fromEntries(this.strings.drought?.crops?.map((c) => [c.id, c.title]) || []);
    },
    ha() {
      return this.strings.commonApp?.unit.ha;
    },
    cropSelection() {
      return this.strings.cropSelection || {};
    },
  },
};
</script>
<style>
</style>
