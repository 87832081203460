const metaData = {
  titleEnd: {
    de: 'Wetterversicherung schnell, einfach und digital',
    en: 'Weather insurance fast, easy and digital',
    pl: 'Weather insurance fast, easy and digital',
  },
  main: {
    de: {
      title: '',
      description: 'Versichern Sie sich gegen schlechtes Wetter. Ob Urlaub oder Veranstaltung. Wir versichern auch Landwirte gegen Dürre und Geschäftstreibende gegen hoche und niedrige Temperatur sowie Schnee und Schneemangel.',
      keywords: 'Wetterversicherung,Urlaubsversicherung,Eventversicherung,Regenversicherung,Dürreversicherung',
    },
    en: {
      title: '',
      description: 'Insure yourself against bad weather. You can insure your vacation or event. We also insure farmers against drought and business owners against high and low temperatures, such as snow and lack of snow',
      keywords: 'weather insurance,vacation insurance,event insurance,rain insurance,drought insurance',
    },
    pl: {
      title: 'Weather insurance fast, easy and digital',
      description: 'Insure yourself against bad weather. You can insure your vacation or event. We also insure farmers against drought and business owners against high and low temperatures, such as snow and lack of snow',
      keywords: 'weather insurance,vacation insurance,event insurance,rain insurance,drought insurance',
    },
  },
  createNewVacationContract: {
    de: {
      title: 'Neuen Urlaubskontrakt erstellen',
    },
    en: {
      title: 'Create a new vacation contract',
    },
    pl: {
      title: 'Create a new vacation contract',
    },
  },
  vacation: {
    de: {
      title: 'Regenversicherung Urlaub - Urlaubsversicherung gegen Regen, Wetterversicherung für Reisen',
      description: 'Urlaub gegen Regen versichern',
      keywords: 'Urlaubsversicherung,Regenversicherung,Regen,Urlaub,Schlechtwetter,Wetterversicherung,Versicherung',
    },
    en: {
      title: 'Weather guarantee insurance, rainy day cover insurance, rain insurance, weather insurance for travel',
      description: 'Insure your vacation and travel against rain',
      keywords: 'Weather guarantee insurance, rainy day cover insurance, rain insurance, vacation insurance, holiday insurance, rain, travel, bad weather, weather insurance, insurance',
    },
    pl: {
      title: 'Weather guarantee insurance, rainy day cover insurance, rain insurance, weather insurance for travel',
      description: 'Insure your vacation and travel against rain',
      keywords: 'Weather guarantee insurance, rainy day cover insurance, rain insurance, vacation insurance, holiday insurance, rain, travel, bad weather, weather insurance, insurance',
    },
  },
  regenversicherungUrlaub: {
    de: {
      title: 'Regenversicherung Urlaub - Urlaubsversicherung gegen Regen, Wetterversicherung für Reisen',
      description: 'Urlaub gegen Regen versichern',
      keywords: 'Urlaubsversicherung,Regenversicherung,Regen,Urlaub,Schlechtwetter,Wetterversicherung,Versicherung',
    },
    en: {
      title: 'Weather guarantee insurance, rainy day cover insurance, rain insurance, weather insurance for travel',
      description: 'Insure your vacation and travel against rain',
      keywords: 'Weather guarantee insurance, rainy day cover insurance, rain insurance, vacation insurance, holiday insurance, rain, travel, bad weather, weather insurance, insurance',
    },
    pl: {
      title: 'Weather guarantee insurance, rainy day cover insurance, rain insurance, weather insurance for travel',
      description: 'Insure your vacation and travel against rain',
      keywords: 'Weather guarantee insurance, rainy day cover insurance, rain insurance, vacation insurance, holiday insurance, rain, travel, bad weather, weather insurance, insurance',
    },
  },
  dertourRegenversicherung: {
    de: {
      title: 'Urlaubsversicherung',
      description: 'Versichern Sie Ihren Urlaub gegen Regen.',
      keywords: 'Urlaubsversicherung,Regenversicherung',
    },
    en: {
      title: 'Vacation insurance',
      description: 'Insure your vacation against rain',
      keywords: 'rain insurance, vacation insurance',
    },
    pl: {
      title: 'Vacation insurance',
      description: 'Insure your vacation against rain',
      keywords: 'rain insurance, vacation insurance',
    },
  },
  hotel: {
    de: {
      title: 'Hotel gegen Regen versichern',
      description: 'Versichern Sie ihr Hotel gegen regnerische Perioden, in denen weniger Gäste kommen und der Umsatz wegbleibt.',
      keywords: '',
    },
    en: {
      title: 'Insure your hotel against rain',
      description: '',
      keywords: '',
    },
    pl: {
      title: 'Insure your hotel against rain',
      description: 'Create a new vacation contract',
      keywords: '',
    },
  },
  drought: {
    de: {
      title: 'Dürreversicherung',
      description: 'Dürre versichern für Landwirtschaft und Acker.',
      keywords: 'Dürreversicherung, Dürre, Landwirtschaft',
    },
    en: {
      title: 'Drought insurance',
      description: 'Insure drought for agriculture and fields',
      keywords: 'drought insurance, drought, agriculture',
    },
    pl: {
      title: 'Drought insurance',
      description: 'Insure drought for agriculture and fields',
      keywords: 'drought insurance, drought, agriculture',
    },
  },
  droughtNoCommitment: {
    de: {
      title: 'Dürreversicherung',
      description: 'Versichern Sie Ihr Feld gegen fehlenden Regen.',
      keywords: 'Dürreversicherung',
    },
    en: {
      title: 'Drought insurance',
      description: 'Insure your field against drought',
      keywords: 'drought insurance',
    },
    pl: {
      title: 'Drought insurance',
      description: 'Insure your field against drought',
      keywords: 'drought insurance',
    },
  },
  rain: {
    de: {
      title: 'Regenversicherung',
      description: 'Versichern Sie Ihr Geschäft gegen Regenfall.',
      keywords: 'Regenversicherung',
    },
    en: {
      title: 'Insurance against rain',
      description: 'Insure your business against rain',
      keywords: 'rain insurance',
    },
    pl: {
      title: 'Insurance against rain',
      description: 'Insure your business against rain',
      keywords: 'rain insurance',
    },
  },
  custom: {
    de: {
      title: 'Gegen Temperatur oder Schnee versichern',
      description: 'Versichern Sie Ihr Geschäft gegen hohe oder niedrige Temperatur sowie Schnee oder fehlenden Schnee.',
      keywords: 'Versicherung gegen Kälte,Versicherung gegen hohe Temperaturen,Versicherung gegen Schnee, Versicherung gegen fehlenden Schnee',
    },
    en: {
      title: 'Insurance against temperature or snow',
      description: 'Insure your business against high or low temperature or against snow or lack of snow.',
      keywords: 'temperature insurance, cold insurance, snow insurance, lack of snow insurance',
    },
    pl: {
      title: 'Insurance against temperature or snow',
      description: 'Insure your business against high or low temperature or against snow or lack of snow.',
      keywords: 'temperature insurance, cold insurance, snow insurance, lack of snow insurance',
    },
  },
  settings: {
    de: {
      title: 'Einstellungen',
    },
    en: {
      title: 'settings',
    },
    pl: {
      title: 'settings',
    },
  },
  insurance: {
    de: {
      title: 'Meine Versicherungen',
    },
    en: {
      title: 'My Insurances',
    },
    pl: {
      title: 'My Insurances',
    },
  },
  insuranceId: {
    de: {
      title: 'Versicherungsübersicht',
    },
    en: {
      title: 'Insurance overview',
    },
    pl: {
      title: 'Insurance overview',
    },
  },
  droughtRisk: {
    de: {
      title: 'Ihr Dürrerisiko',
    },
    en: {
      title: 'Your drought risk',
    },
    pl: {
      title: 'Calculate drought risk',
    },
  },
  cropSelection: {
    de: {
      title: 'Dürrerisiko berechnen',
      description: 'Nutzen Sie unseren Rechner, um Ihr Dürrerisiko zu berechnen.',
      keywords: 'Dürrerisiko,Dürreversicherung',
    },
    en: {
      title: 'Calculate drought risk',
      description: 'Use our calculator to calculate your drought risk.',
      keywords: 'drought risk, drought insurance',
    },
    pl: {
      title: 'Calculate drought risk',
      description: 'Use our calculator to calculate your drought risk.',
      keywords: 'drought risk, drought insurance',
    },
  },
  cropLocation: {
    de: {
      title: 'Standort Ihres Ackers',
    },
    en: {
      title: 'Location of your fields',
    },
    pl: {
      title: 'Location of your fields',
    },
  },
  doneBundle: {
    de: {
      title: 'Fast fertig',
    },
    en: {
      title: 'Almost Done',
    },
    pl: {
      title: 'Almost Done',
    },
  },
  location: {
    de: {
      title: 'Wählen Sie ihren Versicherungstandort',
    },
    en: {
      title: 'Choose your insurance location',
    },
    pl: {
      title: 'Choose your insurance location',
    },
  },
  done: {
    de: {
      title: 'Fast fertig',
    },
    en: {
      title: 'Almost Done',
    },
    pl: {
      title: 'Almost Done',
    },
  },
  additional: {
    de: {
      title: 'Bitte weitere Informationen eingeben',
    },
    en: {
      title: 'Please fill out the additional information',
    },
    pl: {
      title: 'Please fill out the additional information',
    },
  },
  buy: {
    de: {
      title: 'Versicherung abschließen',
    },
    en: {
      title: 'Contract overview',
    },
    pl: {
      title: 'Contract overview',
    },
  },
  buyVacation: {
    de: {
      title: 'Vertragsübersicht',
    },
    en: {
      title: 'Contract overview',
    },
    pl: {
      title: 'Contract overview',
    },
  },
  finalizeVacation: {
    de: {
      title: 'Regenversicherung für Urlaub fertigstellen',
    },
    en: {
      title: 'Finalize rain insurance for vacation',
    },
    pl: {
      title: 'Finalize rain insurance for vacation',
    },
  },
  login: {
    de: {
      title: 'Einloggen',
    },
    en: {
      title: 'Login',
    },
    pl: {
      title: 'Login',
    },
  },
  signup: {
    de: {
      title: 'Registrieren',
    },
    en: {
      title: 'Sign up',
    },
    pl: {
      title: 'Sign up',
    },
  },
  success: {
    de: {
      title: 'Kauf abgeschlossen',
    },
    en: {
      title: 'Insurance bought',
    },
    pl: {
      title: 'Insurance bought',
    },
  },
  letus: {
    de: {
      title: 'Sie erhalten Ihr Angebot sofort auf der nächsten Seite',
    },
    en: {
      title: 'You will receive your offer immediately on the next page',
    },
    pl: {
      title: 'You will receive your offer immediately on the next page',
    },
  },
  forgot: {
    de: {
      title: 'Passwort vergessen',
    },
    en: {
      title: 'Forgot password',
    },
    pl: {
      title: 'Forgot password',
    },
  },
  passwordReset: {
    de: {
      title: 'Passwort zurücksetzen',
    },
    en: {
      title: 'reset password',
    },
    pl: {
      title: 'reset password',
    },
  },
};

export default metaData;
